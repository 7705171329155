<template>
  <div class="waiop">
    <Englishnavigation />
    <div class="banan"><img src="../../assets/English/banan.png" alt="" /></div>
    <div class="chara">
      <img src="../../assets/English/bejd.png" alt="" />

      <div class="three">
        <div class="title">
          <p>Characteristic</p>
          <p class="comp">Three characteristics of the company</p>
        </div>

        <div class="dusty">
          <div class="card">
            <img src="../../assets/English/icon1.png" alt="" />
            <div class="cial">
              <p
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-offset="0"
                data-aos-delay="0"
                data-aos-anchor-placement:="bottom-bottom"
              >
                Financial industry
              </p>
              <div class="trig"></div>
            </div>
          </div>

          <div class="card">
            <img src="../../assets/English/icon2.png" alt="" />
            <div class="cial">
              <p
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-offset="0"
                data-aos-delay="0"
                data-aos-anchor-placement:="bottom-bottom"
              >
                AI diagnosis and treatment
              </p>
              <div class="trig"></div>
            </div>
          </div>
          <div class="card">
            <img src="../../assets/English/icon3.png" alt="" />
            <div class="cial">
              <p
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-offset="0"
                data-aos-delay="0"
                data-aos-anchor-placement:="bottom-bottom"
              >
                Specialized customization
              </p>
              <div class="trig"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="face">
      <div class="recog">
        <img src="../../assets/English/face.png" alt="" />
        <div class="ntiong">
          <img src="../../assets/English/face1.png" alt="" />
        </div>
      </div>

      <div
        class="mylti"
        data-aos="fade-up"
        data-aos-once="true"
        data-aos-offset="0"
        data-aos-delay="0"
        data-aos-anchor-placement:="bottom-bottom"
      >
        <p>Face recognition</p>
        <p class="cong">
          FaceID includes multiple risk control verification methods such as the
          live detection SDK of the client APP, face comparison on the FaceID
          server, certificate recognition, and multiple data cross-verification.
          Through financial-grade face recognition and document recognition
          technology, it can help users realize 1:1 online KYC verification
          conveniently and securely, and solve the complex problem of
          cross-verification of existing online verification methods
        </p>
      </div>
    </div>

    <div class="butty">
      <div
        class="cust"
        data-aos="fade-up"
        data-aos-once="true"
        data-aos-offset="0"
        data-aos-delay="0"
        data-aos-anchor-placement:="bottom-bottom"
      >
        <p>Specialized customization</p>
        <p class="arch">
          Cooperate with business development, research market laws, take users
          as the center, use data information, and combine existing technologies
          to formulate product proposals for enterprises.methods
        </p>
      </div>

      <div class="zati">
        <img src="../../assets/English/zati.png" alt="" />
        <div class="mizati"></div>
      </div>
    </div>

    <Englishbottom />
  </div>
</template>

<script>
import Englishnavigation from "@/components/English/Englishnavigation.vue";
import Englishbottom from "@/components/English/Englishbottom.vue";
import AOS from "aos";

export default {
  components: {
    Englishnavigation,
    Englishbottom,
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
};
</script>

<style scoped lang="less">
.zati {
  width: 530px;
  height: 560px;
  position: relative;
}
.mizati {
  width: 0;
  height: 0;
  border-bottom: 368px solid #ebc509;
  border-left: 399px solid transparent;
  position: absolute;
  right: -37px;
  bottom: -37px;
}
.zati > img {
  width: 530px;
  height: 560px;
  position: absolute;
  z-index: 3;
}
.butty {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cust > p:nth-of-type(1) {
  line-height: 74px;
}
.cust {
  width: 532px;
  color: #fff;
  font-size: 79px;
  margin-right: 69px;
}
.arch {
  width: 463px;
  font-size: 18px;
  padding-top: 40px;
}
.butty {
  width: 100%;
  height: 839px;
  background-color: #01182d;
  margin-top: 336px;
}
.cong {
  color: #4e4e4e;
  font-size: 18px;
  width: 463px;
  margin: 0 auto;
  margin-top: 20px;
}
.mylti {
  width: 633px;
  color: #1c294e;
  font-size: 79px;
  position: absolute;
  top: 83px;
  right: 38px;
}
.ntiong > img {
  width: 100%;
}
.ntiong {
  width: 355px;
  height: 356px;
  border-top: 17px solid #fff;
  border-left: 17px solid #fff;
  position: absolute;
  bottom: -200px;
  left: 168px;
}
.recog > img {
  width: 100%;
  height: 100%;
}
.recog {
  width: 464px;
  height: 465px;
}
.face {
  width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  position: relative;
}
.trig {
  width: 0;
  height: 0;
  border-bottom: 39px solid #ebc509;
  border-left: 39px solid transparent;
  position: absolute;
  right: 0;
  bottom: 0;
}
.card {
  width: 386px;
  text-align: center;
  position: relative;
}
.cial > p {
  color: #4e4e4e;
  font-size: 28px;
  // margin-top: 165px;
  line-height: 330px;
}
.cial {
  width: 386px;
  height: 289px;
  background-color: #fff;
  margin-top: -112px;
}

.dusty {
  width: 1200px;
  margin: 0 auto;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card > img {
  width: 242px;
  height: 242px;
}
.comp {
  color: #ffffff;
  font-size: 28px;
  margin-left: 55px;
  margin-top: 20px;
}
.three {
  width: 100%;
  position: absolute;
  top: 0;
}
.title {
  width: 1170px;
  margin: 0 auto;
  padding-top: 42px;
  color: #ebc509;
  font-size: 40px;
}
.chara > img {
  width: 100%;
  height: 100%;
}
.chara {
  width: 100%;
  height: 800px;
  position: relative;
  top: -9px;
}
.banan > img {
  width: 100%;
  height: 100%;
}
.banan {
  width: 100%;
  // height: 744px;
  margin-top: 76px;
}
</style>