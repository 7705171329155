<template>
  <div class="htmi">
    <div class="hom-top">
      <Headgation></Headgation>

      <div class="banan">
        <!-- <img src="../assets/hoemtop.png" alt="" /> -->
        <img src="../assets/bananp.jpg" alt="" />

        <Oldpage />
      </div>

      <div class="facewai">
        <div class="bodyt">
          <div class="htyu">
            <span class="last">医疗管理</span>
            <span class="last">行为防控</span>
            <span class="last">人脸识别</span>
            <span class="last">安全管理</span>
          </div>
          <div class="htyu2">
            <img src="../assets/wenzi.png" alt="" />
          </div>
        </div>
      </div>

      <!-- 头部 -->
      <div class="intro">
        <div class="business">
          <div>
            <div class="essA"></div>
            <div class="essB"></div>
          </div>
          <div class="siopw">
            <p>主营业务</p>
            <p class="iness">Main business</p>
          </div>
        </div>

        <!-- 图片 -->
        <div class="late">
          <pagemiddle />
        </div>
      </div>
    </div>

    <div class="butt">
      <div class="ggest">
        <div class="Tierui">
          <div class="tiA"></div>
          <p class="tite">成功案例</p>

          <div class="tiB"></div>
        </div>

        <logo :itemimg="items" :distance="245"></logo>
      </div>

      <homeBottom />
    </div>
  </div>
</template>
<script type="text/javascript">
import Headgation from "@/components/Headgation.vue";
import homeBottom from "@/components/homeBottom.vue";
import logo from "@/components/logo.vue";
import pagemiddle from "@/components/pagemiddle.vue";
import Oldpage from "@/components/Oldpage.vue";
import AOS from "aos";

export default {
  components: {
    Headgation,
    homeBottom,
    logo,
    pagemiddle,
    Oldpage,
  },
  data() {
    return {
      iuurl: 1,
      items: "",

      current: 1, //控制当前图片
    };
  },
  created() {
    if (location.href.indexOf("#reloaded") <= 0) {
      location.href = location.href + "#reloaded";
      this.iuurl = 2;
      location.reload();
    }
    if (location.href.indexOf("#reloaded") > 20) {
      this.$router.push("/Oldhome");
    }
  },

  methods: {
    printjan() {
      if (this.current > 0) {
        this.current--;
      }
    },
    print() {
      this.current++;
      if (this.current >= 5) {
        this.current = 0;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
};
</script>
<style type="text/css" scoped>
/* 近远 */

.bodyt {
  z-index: 10;
}

.bodyt {
  font-size: 4px;
  color: hsla(210deg, 100%, 100%, 1);
  width: 100%;
  margin-top: 10%;
  /* height: 700px; */
}
.htyu2 > img {
  width: 216px;
  height: 112px;
}
.htyu2 {
  width: 80%;
  height: 200px;
  margin: 0 auto;
  color: #fff;
  transform: scale(1);
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.htyu {
  width: 80%;
  height: 200px;
  margin: 0 auto;
  text-transform: uppercase;
  letter-spacing: 1.5em;
  color: #fff;
  font-size: clamp(1em, 5vw, 4em);
  animation: breath 10000ms ease-in-out infinite alternate;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.htyu > .last {
  letter-spacing: 20px;
  font-weight: lighter;
  /* font-family: "Roboto"; */
  /* margin-left:150px; */
}

@keyframes breath {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
/* end  */

/* 字体特效 */
.opu {
  color: #fff;
  background-image: linear-gradient(to right, #2c73d2, #0089ba);
  /* background-color: #3d92f5 !important; */
  border-radius: 4px;
}
.flipper {
  position: relative;
  width: 130px;
  height: 44px;
  perspective: 300px;
}

.flipper-object {
  position: absolute;
  transition: transform 1s;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}
.panel {
  position: absolute;
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: mymove 9s infinite;
}
/* Chrome, Safari, Opera */
@-webkit-keyframes mymove {
  50% {
    font-size: 20px;
  }
}

/* Standard syntax */
@keyframes mymove {
  50% {
    font-size: 20px;
  }
}
.flipper-vertical .back {
  transform: rotateX(180deg);
}
.flipper:hover .flipper-vertical {
  transform: rotateX(180deg);
}
/* end  */
.butt {
  width: 100%;
  position: relative;
  z-index: 10;
}
.htmi {
  overflow: hidden;
}
.vas {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9;
}
.banan {
  width: 100%;
}
.banan > img {
  width: 100%;
  /* height: 100%; */
  height: 1613px;
}
.wenzi > img {
  width: 216px;
  height: 112px;
}
.abouts {
  width: 313px;
  height: 48px;
  background: #001850;
  border-left: 0.2px solid #226a97;
  font-size: 14px;
  color: #fff;
  text-align: center;
  line-height: 48px;
}
.fullxia {
  width: 30px;
  height: 1px;
  border: 1px solid #ffffff;
  background-color: #fff;
  margin-bottom: 20px;
}

.Tierui {
  width: 476px;
  margin: 0 auto;
  margin-top: 80px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tiB {
  width: 120px;
  height: 9px;
  background: #0f4f7a;
  border-radius: 6px;
}
.tiA {
  width: 120px;
  height: 9px;
  background: #456e71;
  border-radius: 6px;
}

.facewai {
  width: 100%;
  height: 94.5%;
  font-weight: lighter;
  position: absolute;
  top: 5%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.3);
}
.typefaceD {
  color: #fff;
  font-size: 20px;
  position: absolute;
  top: 10%;
  right: 50%;
}
.typefaceC {
  color: #fff;
  font-size: 20px;
  position: absolute;
  top: 51%;
  right: 10%;
}
.typefaceB {
  color: #fff;
  font-size: 20px;
  position: absolute;
  top: 88%;
  left: 35%;
}
.typeface {
  color: #fff;
  font-size: 20px;
  position: absolute;
  top: 43%;
  left: 2%;
}

.siopw {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 0;
}
.essB {
  width: 128px;
  height: 11px;
  background: #0f4f7a;
  border-radius: 6px;
  margin-left: 214px;
  margin-top: 7px;
}
.essA {
  width: 128px;
  height: 11px;
  background-color: #456e71;
  border-radius: 6px;
  margin-top: 32px;
  margin-right: 30px;
  float: left;
}
.iness {
  color: #8f99af;
  font-size: 30px;
  margin-top: 6px;
}
.business {
  width: 350px;
  height: 136px;
  margin: 20px auto;
  text-align: center;
  color: #fff;
  text-align: center;
  font-size: 40px;
  overflow: hidden;
  position: relative;
}
.but {
  width: 156px;
  height: 43px;
  border: 1px solid #ffffff;
  color: #ffffff;
  flex-shrink: 18px;
  background-color: #002b8e;
  margin-top: 54px;
}
.intros {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 153px;
}
.just {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 92px;
}
.tite {
  text-align: center;
  font-size: 40px;
  font-weight: 500;
  color: #252525;
}
.ggest {
  height: auto;
  background-color: #ffffff;
}
.pize > i {
  color: #fff;
  font-size: 34px;
}
.pize {
  width: 51px;
  height: 51px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.late-wai {
  display: flex;
  align-items: center;
  justify-content: center;
}
.intro {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
  margin: auto;
  z-index: 10;
}
.late {
  margin: 0 auto;
}

.titi-p {
  margin-top: 10px;
}

.titi {
  color: #212121;
  font-size: 20px;
  font-weight: 600;
  margin-left: 37px;
}

.line-img > img {
  width: 60px;
  height: 60px;
}

.line-img {
  width: 70px;
  height: 70px;
  border: 1px solid #979797;
  display: flex;
  align-items: center;
  justify-content: center;
}

.zedrt {
  display: flex;
  align-items: center;
}

.Line-h {
  width: 375px;
  height: 1px;
  border: 1px solid #979797;
  background-color: #979797;
}

.dda {
  margin: 84px 203px;
}

.Line {
  width: 1px;
  height: 101px;
  border: 1px solid #fff;
  background-color: #fff;
  margin-left: 35px;
}

.scien-img > img {
  width: 50px;
  height: 50px;
}

.scien-img {
  width: 70px;
  height: 70px;
  border: 2px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mouse-w > p {
  margin-top: 25px;
  font-size: 20px;
}

.science {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
}

.science > span {
  margin-left: 25px;
  font-size: 20px;
}

.mouse-w {
  margin: 0 61px;
}

.mouse {
  width: 431px;
  height: 379px;
  background: rgba(20, 20, 20, 0.4);
  border-radius: 0px 31px 31px 0px;
  position: absolute;
  top: 20%;
  color: #fff;
  z-index: 10;
}

.hom-top {
  width: 100%;
  padding-bottom: -100px;
  position: relative;
  top: 0;
  z-index: 10;
}

.el-menu.el-menu--horizontal {
  border-bottom: none !important;
}
</style>