<template>
  <div class="waiow">
    <Headgation @getsondata="getson2"></Headgation>

    <div class="bodyt">
      <canvas id="lines"></canvas>
      <div class="htyu">
        <span class="last">医疗管理</span>
        <span class="last">行为防控</span>
        <span class="last">人脸识别</span>
        <span class="last">安全管理</span>
      </div>
      <div class="htyu2">
        <img src="../assets/wenzi.png" alt="" />
      </div>
    </div>

    <div class="middle"></div>

    <div class="boutt">
      <div class="intro">
        <div class="business">
          <div>
            <div class="essA"></div>
            <div class="essB"></div>
          </div>
          <div class="siopw">
            <p>主营业务</p>
            <p class="iness">Main business</p>
          </div>
        </div>

        <!-- 图片 -->
        <div class="late">
          <pagemiddle />
        </div>
      </div>

      <div class="buttcG">
        <div class="ggest" style="background: #13191d">
          <div class="Tierui">
            <div class="tiA"></div>
            <p class="tite" style="color: #fff">成功案例</p>

            <div class="tiB"></div>
          </div>

          <!-- 轮播图 -->
          <div class="rotaon">
            <Rotation></Rotation>
          </div>
          <!-- 轮播图 -->
        </div>
      </div>

      <div class="butt">
        <div class="ggest">
          <div class="Tierui">
            <div class="tiA"></div>
            <p class="tite">我们的客户</p>

            <div class="tiB"></div>
          </div>

          <logo :itemimg="items" :distance="245"></logo>
        </div>

        <homeBottom />
      </div>
    </div>
  </div>
</template>

<script>
import Headgation from "@/components/Headgation.vue";
import homeBottom from "@/components/homeBottom.vue";
import logo from "@/components/logo.vue";
import pagemiddle from "@/components/pagemiddle.vue";
import Dynamic from "@/components/Dynamic.vue";
import Rotation from "@/components/Rotation.vue";
import AOS from "aos";
export default {
  components: {
    Headgation,
    homeBottom,
    logo,
    pagemiddle,
    Dynamic,
    Rotation,
  },
  data() {
    return {
      iuurl: 1,
      items: "",
      current: 1, //控制当前图片
    };
  },
  mounted() {
    this.mount();
  },
  created() {},

  methods: {
    getson2(name) {
      console.log("方式1获得了子类传过来的的值：" + name);
    },
    printjan() {
      if (this.current > 0) {
        this.current--;
      }
    },
    print() {
      this.current++;
      if (this.current >= 5) {
        this.current = 0;
      }
    },
    // 效果代码
    mount() {
      // 计算常数
      const canvas = document.getElementById("lines");
      const ctx = canvas.getContext("2d");
      let width;
      let height;
      class Line {
        constructor(origin, size, length, color, style = "pattern") {
          this.size = size;
          this.origin = origin;
          this.length = length;
          this.color = color;
          this.style = style;
          this.origin = `M${origin.x},${origin.y}`;
          this.offSet = 0;
          this.line = null;
          this.offSetSpeed = length / size;
        }
        getColorString() {
          return `hsla(${this.color.h}deg,${this.color.s}%,${this.color.l}%,${this.color.a})`;
        }
        generators() {
          return [
            {
              line: `h${this.size}`,
              mag: this.size,
            },
            {
              line: `h-${this.size}`,
              mag: this.size,
            },
            {
              line: `v${this.size}`,
              mag: this.size,
            },
            {
              line: `v-${this.size}`,
              mag: this.size,
            },
            {
              line: `l${this.size},${this.size}`,
              mag: Math.hypot(this.size, this.size),
            },
            {
              line: `l${this.size}-${this.size}`,
              mag: Math.hypot(this.size, this.size),
            },
            {
              line: `l-${this.size},${this.size}`,
              mag: Math.hypot(this.size, this.size),
            },
            {
              line: `l-${this.size}-${this.size}`,
              mag: Math.hypot(this.size, this.size),
            },
          ];
        }
        generate() {
          let segments = this.generators(this.size);
          let path = this.origin;
          let mag = 0;
          let fragment;
          let i;
          for (i = 0; i < this.length; i += 1) {
            fragment = segments[(Math.random() * segments.length) | 0];
            path += ` ${fragment.line}`;
            mag += fragment.mag;
          }
          this.line = {
            path,
            mag,
          };
          return this;
        }
        renderStyle(style) {
          if (style === "glitches") {
            ctx.lineDashOffset = this.line.mag + this.offSet;
            ctx.setLineDash([
              this.size ** 1.5,
              (this.line.mag / this.length) * this.size ** 2,
            ]);
            this.offSet += 20;
            // this.size / (this.size ** 2);
            ctx.lineWidth = 2;
            return this;
          }
          if (style === "pattern") {
            ctx.lineDashOffset = this.line.mag - this.offSet;
            ctx.setLineDash([this.line.mag, this.line.mag]);
            this.offSet += 10;
            //this.size / (this.size ** 100);
            ctx.lineWidth = 0.2;
          }
        }
        mutatePath() {
          let lineFragment = this.line.path.split(" ").slice(1);
          let generator = this.generators();
          lineFragment[(Math.random() * lineFragment.length) | 0] =
            generator[(Math.random() * generator.length) | 0].line;
          this.line.path = `${this.line.path.split(" ")[0]} ${lineFragment.join(
            " "
          )}`;
        }
        draw() {
          !this.line && this.generate();

          ctx.strokeStyle = this.getColorString();
          this.renderStyle(this.style);
          ctx.lineCap = "round";
          ctx.lineJoin = "round";
          ctx.stroke(new Path2D(this.line.path));
          return this;
        }
      }
      function clear() {
        ctx.fillStyle = `#13191D`; //颜色 hsla(200deg, 20%, 10%, 0.3)
        ctx.fillRect(0, 0, width, height);
      }
      function generateLines(amount) {
        let lines = [];
        let styles = [
          {
            size: 1.25,
            style: "pattern",
            color: { h: 210, s: 100, l: 70, a: 0.5 },
          },
          {
            size: 2.5,
            style: "pattern",
            color: { h: 190, s: 90, l: 50, a: 0.3 },
          },
          {
            size: 5,
            style: "pattern",
            color: { h: 210, s: 70, l: 60, a: 0.2 },
          },
          {
            size: 10,
            style: "pattern",
            color: { h: 310, s: 80, l: 55, a: 0.15 },
          },
          {
            size: 20,
            style: "pattern",
            color: { h: 200, s: 25, l: 35, a: 0.12 },
          },
          {
            size: 20,
            style: "pattern",
            color: { h: 210, s: 20, l: 40, a: 0.12 },
          },
          {
            size: 40,
            style: "pattern",
            color: { h: 190, s: 40, l: 50, a: 0.12 },
          },
          {
            size: 80,
            style: "pattern",
            color: { h: 220, s: 50, l: 60, a: 0.12 },
          },
          {
            size: 40,
            style: "glitches",
            color: { h: 300, s: 100, l: 50, a: 0.3 },
          },
          {
            size: 20,
            style: "glitches",
            color: { h: 210, s: 100, l: 50, a: 0.3 },
          },
          {
            size: 60,
            style: "glitches",
            color: { h: 30, s: 100, l: 50, a: 0.3 },
          },
        ];
        for (let i = 0; i < amount; i += 1) {
          let style = styles[(Math.random() ** 2 * styles.length) | 0];
          lines.push(
            new Line(
              { x: width * 0.5, y: height * 0.5 },
              style.size,
              500 + Math.random() * 1000,
              style.color,
              style.style
            )
          );
        }
        return lines;
      }
      let id;
      function resize() {
        id = cancelAnimationFrame(id);
        width = window.innerWidth;
        height = 500;
        canvas.width = width;
        canvas.height = height;
        const lines = generateLines(400); //箔条显示40
        function update() {
          if (!(id % 3)) {
            clear();
            lines.forEach((line) => {
              line.draw();
              if (!(id % 5) && Math.random() > 0.95) {
                line.mutatePath();
              }
            });
          }
          id = requestAnimationFrame(update);
        }
        id = requestAnimationFrame(update);
      }
      window.addEventListener("resize", resize, {
        passive: true,
      });
      resize();
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
  },
};
</script>

<style type="text/css" scoped>
.rotaon {
  margin-top: 50px;
}
.middle {
  /* height: 450px; */
  /* height: 225px; */
  /* background-color: #13191D; */
  /* background-image: linear-gradient(#29293D, #13191d); */
}
.topim {
  width: 100%;
  position: relative;
}

/* 背景效果 */
#lines {
  width: 100% !important;
  height: 100% !important;
}
* {
  padding: 0;
  margin: 0;
}

.bodyt {
  font-size: 4px;
  color: hsla(210deg, 100%, 100%, 1);
  width: 100%;
  margin-top: 10%;
  /* height: 700px; */
}
.htyu2 > img {
  width: 216px;
  height: 112px;
}
.htyu2 {
  width: 80%;
  height: 200px;
  margin: 0 auto;
  color: #fff;
  transform: scale(1);
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.htyu {
  width: 80%;
  height: 200px;
  margin: 0 auto;
  text-transform: uppercase;
  letter-spacing: 1.5em;
  color: #fff;
  font-size: clamp(1em, 5vw, 4em);
  animation: breath 10000ms ease-in-out infinite alternate;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.htyu > .last {
  letter-spacing: 20px;
  font-weight: lighter;
  font-family: "Roboto";
  /* margin-left:150px; */
}

@keyframes breath {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background-color: hsla(240deg, 20%, 20%, 1);
}
/* end/ */
/* 字体特效 */
.opu {
  color: #fff;
  background-image: linear-gradient(to right, #2c73d2, #0089ba);
  /* background-color: #3d92f5 !important; */
  border-radius: 4px;
}
.flipper {
  position: relative;
  width: 130px;
  height: 44px;
  perspective: 300px;
}
.flipper-object {
  position: absolute;
  transition: transform 1s;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}
.panel {
  position: absolute;
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flipper-vertical .back {
  transform: rotateX(180deg);
}
.flipper:hover .flipper-vertical {
  transform: rotateX(180deg);
}
/* end  */
.buttcG {
  width: 100%;
  position: relative;
  z-index: 10;
}
.butt {
  width: 100%;
  position: relative;
  z-index: 10;
}
.htmi {
  overflow: hidden;
}
.vas {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9;
}
.banan {
  width: 100%;
}
.banan > img {
  width: 100%;
  height: 100%;
}
.wenzi > img {
  width: 216px;
  height: 112px;
}
.abouts {
  width: 313px;
  height: 48px;
  background: #001850;
  border-left: 0.2px solid #226a97;
  font-size: 14px;
  color: #fff;
  text-align: center;
  line-height: 48px;
}
.fullxia {
  width: 30px;
  height: 1px;
  border: 1px solid #ffffff;
  background-color: #fff;
  margin-bottom: 20px;
}
.Tierui {
  width: 476px;
  margin: 0 auto;
  padding-top: 80px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tiB {
  width: 120px;
  height: 9px;
  background: #0f4f7a;
  border-radius: 6px;
}
.tiA {
  width: 120px;
  height: 9px;
  background: #456e71;
  border-radius: 6px;
}

.siopw {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 0;
}
.essB {
  width: 128px;
  height: 11px;
  background: #0f4f7a;
  border-radius: 6px;
  margin-left: 214px;
  margin-top: 7px;
}
.essA {
  width: 128px;
  height: 11px;
  background-color: #456e71;
  border-radius: 6px;
  margin-top: 32px;
  margin-right: 30px;
  float: left;
}
.iness {
  color: #8f99af;
  font-size: 30px;
  margin-top: 6px;
}
.business {
  width: 350px;
  height: 136px;
  margin: 0 auto;
  margin-bottom: 50px;
  text-align: center;
  color: #fff;
  text-align: center;
  font-size: 40px;
  overflow: hidden;
  position: relative;
}
.but {
  width: 156px;
  height: 43px;
  border: 1px solid #ffffff;
  color: #ffffff;
  flex-shrink: 18px;
  background-color: #002b8e;
  margin-top: 54px;
}
.intros {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 153px;
}
.just {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 92px;
}
.tite {
  text-align: center;
  font-size: 40px;
  font-weight: 500;
  color: #252525;
}
.ggest {
  height: auto;
  background-color: #ffffff;
}
.pize > i {
  color: #fff;
  font-size: 34px;
}
.pize {
  width: 51px;
  height: 51px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.late-wai {
  display: flex;
  align-items: center;
  justify-content: center;
}
.intro {
  padding-top: 500px;
  padding-bottom: 40px;
  margin: auto;
  background: #13191d;
}
.late {
  margin: 0 auto;
}

.titi-p {
  margin-top: 10px;
}

.titi {
  color: #212121;
  font-size: 20px;
  font-weight: 600;
  margin-left: 37px;
}

.line-img > img {
  width: 60px;
  height: 60px;
}

.line-img {
  width: 70px;
  height: 70px;
  border: 1px solid #979797;
  display: flex;
  align-items: center;
  justify-content: center;
}

.zedrt {
  display: flex;
  align-items: center;
}

.Line-h {
  width: 375px;
  height: 1px;
  border: 1px solid #979797;
  background-color: #979797;
}

.dda {
  margin: 84px 203px;
}

.Line {
  width: 1px;
  height: 101px;
  border: 1px solid #fff;
  background-color: #fff;
  margin-left: 35px;
}

.scien-img > img {
  width: 50px;
  height: 50px;
}

.scien-img {
  width: 70px;
  height: 70px;
  border: 2px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mouse-w > p {
  margin-top: 25px;
  font-size: 20px;
}

.science {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
}

.science > span {
  margin-left: 25px;
  font-size: 20px;
}

.mouse-w {
  margin: 0 61px;
}

.mouse {
  width: 431px;
  height: 379px;
  background: rgba(20, 20, 20, 0.4);
  border-radius: 0px 31px 31px 0px;
  position: absolute;
  top: 20%;
  color: #fff;
  z-index: 10;
}

.hom-top {
  width: 100%;
  padding-bottom: -100px;
  position: relative;
  top: 0;
  z-index: 10;
}

.el-menu.el-menu--horizontal {
  border-bottom: none !important;
}
</style>
