<template>
  <div>
    <div class="hom-top">
      <div :class="atter ? 'biao' : 'change'" :style="{ background: bacl }">
        <div class="loga">
          <img @click="Picture" src="../assets/logo-01.png" alt="" />


          <div class="shares">
            <p>代码  000148</p>
            <p></p>
            <p>上海专精特新专板首批上板企业</p>
          </div>

        </div>
        <p v-for="(item, index) in listA" :key="index">
          <router-link
            :class="atter ? 'linkeA' : 'linkeB'"
            :to="item.frameAddr"
          >
            <span @mouseover="mouseOver(item, index)">{{ item.mTitle }} </span>
          </router-link>
        </p>
        <div class="right" :style="{ color: sizeyL }">
          <span>
            <router-link :style="{ color: sizeyL }" to="/Homepage"
              >EN</router-link
            >
          </span>
          <span class="sux">|</span>
          <span>CN</span>
          <!-- <span class="rigt-img"><img src="../assets/fad.png" alt="" /></span> -->
        </div>
      </div>
      <div
        class="Secon"
        v-show="daty"
        :style="{ background: xiabi }"
        @mouseleave="mouseLeave()"
      >
        <div class="Secondary">
          <div class="Secondary-p" v-for="(numer, index) in lock" :key="index">
            <p
              v-if="picture == 2"
              class="titi"
              @click="topage(numer.frameAddr, numer.mTitle, numer)"
              style="cursor: pointer"
            >
              <span :style="{ color: numer.mColour }">{{ numer.mTitle }}</span>
              <!-- <router-link :to="numer.link" :style="{ color: numer.Font }">{{ numer.name }}</router-link> -->
              <img v-show="picture == 2" src="../assets/jinao.png" alt="" />
            </p>
            <p v-else class="titi">
              <span :style="{ color: numer.mColour }">{{ numer.mTitle }}</span>
              <!-- <router-link :to="numer.link" :style="{ color: numer.Font }">{{ numer.name }}</router-link> -->
            </p>
            <div class="xian"></div>
            <div class="dary">
              <p v-for="(mark, index) in numer.children" :key="index">
                <router-link :to="mark.frameAddr">{{
                  mark.mTitle
                }}</router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div style="height: 85px"></div> -->
  </div>
</template>
<script type="text/javascript">
import { homepage } from "@/api";

export default {
  name: "Headgation",
  data() {
    return {
      listA: "",
      picture: -1,
      lock: "",
      daty: false,
      xiabi: "",
      activeIndex: "/",
      atter: true, //选没选
      bacl: "rgba(255,255,255,0.001)", //背景
      sizeyL: "#fff", //字体
      style: {},
    };
  },
  mounted() {
    this.app();
    window.addEventListener("scroll", this.scrollChange); //监听页面滚动
  },
  methods: {
    Picture() {
      this.$router.push({ path: "/" });
    },
    app() {
      homepage().then((res) => {
        this.listA = res.rows;
      });
    },
    // 标题跳转
    topage(link, name, id) {
      if (name === id.mTitle) {
        this.$router.push({ path: link, query: { pNo: id.mNo } });
      }
    },
    mouseOver(val, num) {
      this.picture = num;
      // console.log("当前",num)
      this.lock = "";
      if (this.listA[num].children.length != 0) {
        this.daty = true;
        this.atter = false;
        this.sizeyL = "#fff";
        this.xiabi = "#fff";
        this.lock = this.listA[num].children;
      } else {
        this.lock = "";
        this.xiabi = "";
        // console.log(this.lock);
      }
      localStorage.setItem("accessTop", JSON.stringify(val));
    },
    mouseLeave() {
      this.lock = "";
      this.daty = false;
      this.atter = true;
    },
    scrollChange() {
      if (window.scrollY >= 100) {
        this.bacl = "#002B8E";
        this.sizeyL = "#fff";
      } else {
        this.bacl = "rgba(255,255,255,0.001)";
        this.sizeyL = "#fff";
      }
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>
<style type="text/css" scoped lang='less'>
.titi > img {
  width: 20px;
  height: 10px;
  margin-right: 10%;
}
.loga {
  flex: 1;
  margin-left: 50px;
  display: flex;
  align-items: center;
}
.shares {
  height: 53px;
  line-height: 23px;
}
.shares > p {
  width: auto;
  height: 12px;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  margin-left: 20px;
}

.loga > img {
  width: auto;
  height: 53px;
  cursor: pointer; /*鼠标变小手*/
}
.loga > p {
  color: #fff;
  font-size: 16px;
  margin-left: 20px;
}
.sux {
  width: 0px !important;
  margin: 0 8px;
}
.right {
  display: flex;
  align-items: center;
}
.right > span {
  display: block;
  width: auto !important;
  height: 34px;
  line-height: 34px;
  text-align: center;
}
.rigt-img {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.rigt-img > img {
  width: 24px;
  height: 24px;
}
.right {
  margin-left: 180px;
  margin-right: 57px;
}
.biao > p:hover {
  zoom: 1;
  transform: scale(1.2);
}
.change > p:hover {
  zoom: 1;
  transform: scale(1.2);
}
.biao > p,
.change > p {
  width: 80px;
  height: 30px;
  margin: 0 30px;
  text-align: center;
  line-height: 30px;
}
.change {
  height: 95px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #002b8e !important;
  color: #fff !important;
  font-size: 18px;
}
.biao {
  height: 95px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #fff;
  font-size: 18px;
}
.linkeB:hover {
  border-bottom: 2px solid #fff;
}
.linkeA {
  color: #fff !important;
  padding-bottom: 6px;
}

.linkeB {
  color: #fff !important;
  padding-bottom: 6px;
}

a {
  text-decoration: none;
  color: #757575;
}

.xian {
  width: 90%;
  border: 0.5px solid #e2e2e2;
  margin: 13px 0;
  background-color: #e2e2e2;
}
.Secon {
  width: 100%;
  padding-top: 50px;
}
.dary > p:hover {
  font-size: 19px;
}
.dary > p {
  width: 300px;
  height: 30px;
  line-height: 30px;
  color: #757575;
  font-size: 12px;
}
.titi {
  color: #595959;
  font-size: 18px;
  margin-bottom: 26px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Secondary {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}
.Secondary-p {
  width: calc((100%-30px) / 3);
  width: 300px;
  margin-bottom: 29px;
}

.hom-top {
  overflow: hidden;
  width: 100%;
  position: fixed !important;
  top: 0;
  z-index: 10000;
}
.el-menu-demo {
  width: 100%;
  border-bottom: none !important;
}
.el-menu-item {
  line-height: 95px;
}
</style>