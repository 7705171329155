<template>
  <div>
    <div class="intro">
      <div class="zedrt">
        <div class="tiA"></div>
        <div class="typeface">
<!--          <p>加入我们</p>-->
          <p>{{ companyData.copyTitle }}</p>
        </div>
        <div class="tiB"></div>
      </div>
    </div>

    <div class="post">
      <h3>热招岗位</h3>
      <div class="tiao"></div>

      <div class="tare">
        <p class="tare-p">上海</p>
        <p class="poi">Java开发工程师</p>
        <p>前端开发工程师</p>
      </div>

      <div style="height: 50px"></div>
      <div class="tare">
        <p class="tare-p">北京</p>
        <p class="poi">售前顾问</p>
      </div>
    </div>

    <div class="post">
      <h3>简历投递与咨询</h3>
      <div class="tiao"></div>
      <div class="service">
        <p>hr@aventura.net.cn</p>
        <p>021-56530009</p>
      </div>
    </div>

    <div style="height: 121px"></div>
  </div>
</template>

<script>
import {company} from "@/api";

export default {
  data(){
    return{
      companyData: {},
      queryForm:{
        currentPage: 1,
        pageSize: 10,
        isEnable: -1
      },
    }
  },
  created() {
    company(this.queryForm).then(res => {
      this.companyData = res.data[3]
    })
  },
};
</script>

<style scoped lang="less">
h3{
  font-size: 20px;
  margin-bottom: 10px;
}
.service {
  margin-top: 38px;
  color: #f44336;
  font-size: 16px;
  font-weight: 600;
  line-height: 31px;
}
.poi {
  margin-bottom: 18px;
}
.tare-p {
  color: #f44336;
  font-weight: 600;
  margin-bottom: 31px;
}
.tare {
  margin-top: 39px;
  color: #666666;
  font-size: 16px;
}
.tiao {
  width: 26px;
  height: 1px;
  border: 1px solid #a01313;
  background-color: #a01313;
  margin: 0 auto;
}
.post {
  text-align: center;
  margin-top: 75px;
}
.intro {
  margin-top: 76px;
}
//end
.typeface {
  color: #282828;
  font-size: 40px;
  margin: 0 29px;
}
.zedrt {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 81px;
}
.tiB {
  width: 120px;
  height: 9px;
  background: #0f4f7a;
  border-radius: 6px;
}
.tiA {
  width: 120px;
  height: 9px;
  background: #456e71;
  border-radius: 6px;
}
// 标题
</style>
