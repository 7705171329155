<template>
  <div class="header">
    <div class="card-carousel-wrapper">
      <div
        class="pize"
        style="background-color: #f44336"
        @click="moveCarousel(-1)"
        :disabled="atHeadOfList"
      >
        <i class="el-icon-arrow-left"></i>
      </div>

      <div class="card-carousel">
        <div class="card-carousel--overflow-container">
          <div
            class="card-carousel-cards"
            :style="{
              transform: 'translateX' + '(' + currentOffset + 'px' + ')',
            }"
          >
            <div
              class="card-carousel--card"
              v-for="(item, index) in items"
              :key="index"
            >
              <el-image
                v-if="itemimg.length !== 0"
                :src="item.img"
                fit="contain"
              ></el-image>
              <el-image v-else :src="item.list[0].url" fit="contain"></el-image>
            </div>
          </div>
        </div>
      </div>

      <div
        class="pize"
        style="background-color: #051c30"
        @click="moveCarousel(1)"
        :disabled="atEndOfList"
      >
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
  </div>
</template>


<script>
import { prose } from "@/api";

export default {
  props: ["itemimg", "distance"],
  components: {},
  data() {
    return {
      boxwinde: window.location.origin,
      items: "",
      currentOffset: 0,
      windowSize: 3,
      paginationFactor: this.distance,
      imge: this.itemimg,
    };
  },
  mounted() {
    this.capou();
  },
  computed: {
    atEndOfList() {
      return (
        this.currentOffset <=
        this.paginationFactor * -1 * (this.items.length - this.windowSize)
      );
    },
    atHeadOfList() {
      return this.currentOffset === 0;
    },
  },
  methods: {
    capou() {
      prose().then((res) => {
        // console.log(this.imge,'sssssssssssssss');
        if (this.imge !== "") {
          this.items = this.imge;
        } else {
          this.items = res.data;
          this.items.forEach((res) => {
            res.list[0].url = process.env.VUE_APP_SRC1 + res.list[0].url;
          });
        }
      });
    },
    moveCarousel(direction) {
      // Find a more elegant way to express the :style. consider using props to make it truly generic
      if (direction === 1 && !this.atEndOfList) {
        this.currentOffset -= this.paginationFactor;
      } else if (direction === -1 && !this.atHeadOfList) {
        this.currentOffset += this.paginationFactor;
      }
    },
  },
};
</script>


<style scoped>
.el-image__inne {
  width: 77%;
}
.el-image {
  width: 200px;
  height: 100%;
}
.pize > i {
  color: #fff;
  font-size: 34px;
}
.pize {
  width: 51px;
  height: 51px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-carousel-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  color: #666a73;
}

.card-carousel {
  display: flex;
  justify-content: center;
  width: 77%;
}
.card-carousel--overflow-container {
  overflow: hidden;
}
.card-carousel--nav__left,
.card-carousel--nav__right {
  display: inline-block;
  width: 15px;
  height: 15px;
  padding: 10px;
  box-sizing: border-box;
  border-top: 2px solid #42b883;
  border-right: 2px solid #42b883;
  cursor: pointer;
  margin: 0 10px;
  transition: transform 150ms linear;
}
.card-carousel--nav__left[disabled],
.card-carousel--nav__right[disabled] {
  opacity: 0.2;
  border-color: black;
}
.card-carousel--nav__left {
  transform: rotate(-135deg);
}
.card-carousel--nav__left:active {
  transform: rotate(-135deg) scale(0.9);
}
.card-carousel--nav__right {
  transform: rotate(45deg);
}
.card-carousel--nav__right:active {
  transform: rotate(45deg) scale(0.9);
}

.card-carousel-cards {
  display: flex;
  align-items: center;
  transition: transform 150ms ease-out;
  transform: translatex(0px);
}
.card-carousel--card > img {
  width: 300px;
  height: 300px;
}
.card-carousel-cards .card-carousel--card {
  margin: 0 62px;
  cursor: pointer;

  background-color: #fff;
  border-radius: 4px;
  z-index: 3;
  margin-bottom: 2px;
}
.card-carousel-cards .card-carousel--card:first-child {
  margin-left: 0;
}
.card-carousel-cards .card-carousel--card:last-child {
  margin-right: 0;
}
.card-carousel-cards .card-carousel--card img {
  vertical-align: bottom;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transition: opacity 150ms linear;
  user-select: none;
}
.card-carousel-cards .card-carousel--card img:hover {
  opacity: 0.5;
}
.card-carousel-cards .card-carousel--card--footer {
  border-top: 0;
  padding: 7px 15px;
}
.card-carousel-cards .card-carousel--card--footer p {
  padding: 3px 0;
  margin: 0;
  margin-bottom: 2px;
  font-size: 19px;
  font-weight: 500;
  color: #2c3e50;
  user-select: none;
}
.card-carousel-cards .card-carousel--card--footer p:nth-of-type(2) {
  font-size: 12px;
  font-weight: 300;
  padding: 6px;
  background: rgba(40, 44, 53, 0.06);
  display: inline-block;
  position: relative;
  margin-left: 4px;
  color: #666a73;
}
.card-carousel-cards .card-carousel--card--footer p:nth-of-type(2):before {
  content: "";
  float: left;
  position: absolute;
  top: 0;
  left: -12px;
  width: 0;
  height: 0;
  border-color: transparent rgba(40, 44, 53, 0.06) transparent transparent;
  border-style: solid;
  border-width: 12px 12px 12px 0;
}
.card-carousel-cards .card-carousel--card--footer p:nth-of-type(2):after {
  content: "";
  position: absolute;
  top: 10px;
  left: -1px;
  float: left;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background: white;
  box-shadow: -0px -0px 0px #004977;
}

h1 {
  font-size: 3.6em;
  font-weight: 100;
  text-align: center;
  margin-bottom: 0;
  color: #42b883;
}
</style>
