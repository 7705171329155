<template>
  <div>
    <div>
      <canvas class="vas" id="canvas"></canvas>
      <div class="controls"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dynamic",
  created() {
    this.mount();
  },
  methods: {
    mount() {
      // 计算常数
      "use strict";

      const {
        abs,
        acos,
        asin,
        atan,
        atan2,
        ceil,
        cos,
        floor,
        max,
        min,
        PI,
        pow,
        random,
        round,
        sin,
        sqrt,
        tan,
      } = Math;
      const HALF_PI = 0.5 * PI;
      const QUART_PI = 0.25 * PI;
      const TAU = 2 * PI;
      const TO_RAD = PI / 180;
      const G = 6.67 * pow(10, -11);
      const EPSILON = 2.220446049250313e-16;
      const rand = (n) => n * random();
      const randIn = (_min, _max) => rand(_max - _min) + _min;
      const randRange = (n) => n - rand(2 * n);
      const fadeIn = (t, m) => t / m;
      const fadeOut = (t, m) => (m - t) / m;
      const fadeInOut = (t, m) => {
        let hm = 0.5 * m;
        return abs(((t + hm) % m) - hm) / hm;
      };
      const dist = (x1, y1, x2, y2) => sqrt(pow(x2 - x1, 2) + pow(y2 - y1, 2));
      const angle = (x1, y1, x2, y2) => atan2(y2 - y1, x2 - x1);
      const lerp = (a, b, t) => (1 - t) * a + t * b;
      const clamp = (n, _min, _max) => min(max(n, _min), _max);
      const norm = (n, _min, _max) => (n - _min) / (_max - _min);
      const fract = (n) => n - (n | 0);
      const vh = (p) => p * window.innerHeight * 0.01;
      const vw = (p) => p * window.innerWidth * 0.01;
      const vmin = (p) => min(vh(p), vw(p));
      const vmax = (p) => max(vh(p), vw(p));
      const intToRGBA = (n) => {
        let r, g, b, a;

        n >>>= 0;

        r = (n & 0xff000000) >>> 24;
        g = (n & 0xff0000) >>> 16;
        b = (n & 0xff00) >>> 8;
        a = (n & 0xff) / 255;

        return `rgba(${[r, g, b, a].join()})`;
      };
      const drawTypes = {
        FILL: "fill",
        STROKE: "stroke",
      };

      const textAlignTypes = {
        CENTER: "center",
        END: "end",
        LEFT: "left",
        RIGHT: "right",
        START: "start",
      };

      const textBaselineTypes = {
        ALPHABETIC: "alphabetic",
        BOTTOM: "bottom",
        HANGING: "hanging",
        MIDDLE: "middle",
        TOP: "top",
      };

      Array.prototype.lerp = function (t = [], a = 0) {
        this.forEach((n, i) => (this[i] = lerp(n, t[i], a)));
      };

      Float32Array.prototype.get = function (i = 0, n = 0) {
        const t = i + n;

        let r = [];

        for (; i < t; i++) {
          r.push(this[i]);
        }

        return r;
      };

      class PropsArray {
        constructor(count = 0, props = [], type = "float") {
          this.count = count;
          this.props = props;
          this.spread = props.length; // TODO: Need to implement indexing based on spread
          this.values =
            type === "float"
              ? new Float32Array(count * props.length)
              : new Uint32Array(count * props.length);
        }
        get length() {
          return this.values.length;
        }
        set(a = [], i = 0) {
          this.values.set(a, i);
        }
        setMap(o = {}, i = 0) {
          this.set(Object.values(o), i);
        }
        get(i = 0) {
          return this.values.get(i, this.spread);
        }
        getMap(i = 0) {
          return this.get(i).reduce(
            (r, v, i) => ({
              ...r,
              ...{
                [this.props[i]]: v,
              },
            }),

            {}
          );
        }
        forEach(cb) {
          let i = 0;

          for (; i < this.length; i += this.spread) {
            cb(this.get(i), i, this);
          }
        }
        map(cb) {
          let i = 0;

          for (; i < this.length; i += this.spread) {
            this.set(cb(this.get(i), i, this), i);
          }
        }
        async *read() {
          let i = 0;

          for (; i < this.length; i += this.spread) {
            yield {
              index: i,
              value: this.get(i),
            };
          }
        }
      }

      function createOffscreenCanvas(width, height) {
        let _canvas;

        if (typeof OffscreenCanvas !== undefined) {
          _canvas = new OffscreenCanvas(parseFloat(width), parseFloat(height));
        } else {
          _canvas = createCanvas(width, height);
        }

        return _canvas;
      }

      function createCanvas(width, height) {
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;

        return canvas;
      }

      function createContext2D(
        width = innerWidth,
        height = innerHeight,
        contextAttributes
      ) {
        return createCanvas(width, height).getContext("2d", contextAttributes);
      }

      function createOffscreenContext2D(
        width = innerWidth,
        height = innerHeight,
        contextAttributes
      ) {
        return createOffscreenCanvas(width, height).getContext(
          "2d",
          contextAttributes
        );
      }

      function createRenderingContext(width, height) {
        const contextAttributes = {
          desynchronized: true,
          willReadFrequently: true,
        };

        const ctx = createContext2D(width, height, contextAttributes);
        const buffer = createOffscreenContext2D(
          width,
          height,
          contextAttributes
        );

        ctx.canvas.style.position = "absolute";

        document.body.appendChild(ctx.canvas);

        return {
          buffer,
          ctx,
        };
      }

      const particleCount = 20000;
      const positionProps = ["x", "y", "z"];
      const velocityProps = ["vx", "vx", "vz", "vw"];
      const ageProps = ["age", "life"];
      const colorProps = ["r", "g", "b"];
      const simplex = new SimplexNoise();

      let scene;
      let camera;
      let renderer;
      let material;
      let mesh;
      let time;
      let geometry;
      let positions;
      let velocities;
      let ages;
      let colors;
      let controls;

      addEventListener("DOMContentLoaded", start);
      addEventListener("resize", resize);

      function start() {
        time = 0;
        createScene();
        createCamera();
        createParticles();
        createMesh();
        createRenderer();
        controls = new THREE.OrbitControls(camera, renderer.domElement);
        render();
      }

      function createScene() {
        scene = new THREE.Scene();
      }

      function createCamera() {
        camera = new THREE.PerspectiveCamera(
          50,
          innerWidth / innerHeight,
          0.1,
          10000
        );
          // console.log(camera,'ssssssss');
        
        camera.position.z = 400; // 控制球大小
      }

      function createParticles() {
        positions = new PropsArray(particleCount, positionProps);
        velocities = new PropsArray(particleCount, velocityProps);
        colors = new PropsArray(particleCount, colorProps);
        ages = new PropsArray(particleCount, ageProps);
        positions.map(createPosition);
        velocities.map(createVelocity);
        colors.map(createColor);
        ages.map(createAge);
      }

      function resetParticle(i) {
        positions.set(createPosition(), i * positions.spread);
        velocities.set(createVelocity(), i * velocities.spread);
        colors.set(createColor(), i * colors.spread);
        ages.set(createAge(), i * ages.spread);
      }

      function createPosition(v, i) {
        let d, p, t, x, y, z, vx, vy, vz;

        d = 100;
        p = rand(TAU);
        t = rand(PI);

        x = d * sin(p) * cos(t);
        y = d * sin(p) * sin(t);
        z = d * cos(p);

        return [x, y, z];
      }

      function createVelocity(v, i) {
        let vx, vy, vz, vw;

        vx = vy = vz = 0;
        vw = 2 + rand(4);

        return [vx, vy, vz, vw];
      }

      function createAge(v, i) {
        let age, life;

        age = 0;
        life = rand(200) + 200;

        return [age, life];
      }

      function createColor(v, i) {
        let r, g, b;

        r = fadeIn(20 + rand(20), 360);
        g = fadeIn(90 + rand(90), 360);
        b = fadeIn(120 + rand(140), 360);

        return [r, g, b];
      }

      function createMesh() {
        const uniforms = {
          u_time: {
            type: "f",
            value: 0.0,
          },

          u_texture: {
            type: "sampler2D",
            value: new THREE.TextureLoader().load(
              "//repo.bfw.wiki/bfwrepo/icon/5de90f964d501.png"
            ),
          },

          u_resolution: {
            type: "v2",
            value: new THREE.Vector2(50, 50),
          },
        };

        material = new THREE.ShaderMaterial({
          vertexShader: document.getElementById("vert-shader").textContent,
          fragmentShader: document.getElementById("frag-shader").textContent,
          blending: THREE.AdditiveBlending,
          depthTest: true,
          depthWrite: false,
          transparent: true,
          uniforms,
        });

        geometry = new THREE.BufferGeometry();

        geometry.setAttribute(
          "position",
          new THREE.BufferAttribute(positions.values, positions.spread)
        );
        geometry.setAttribute(
          "color",
          new THREE.BufferAttribute(colors.values, colors.spread)
        );
        geometry.setAttribute(
          "age",
          new THREE.BufferAttribute(ages.values, ages.spread)
        );

        mesh = new THREE.Points(geometry, material);
        mesh.rotation.x = 0.5;

        camera.lookAt(mesh.position);

        scene.add(mesh);
      }

      function createRenderer() {
        renderer = new THREE.WebGLRenderer({
          alpha: true,
          canvas: document.getElementById("canvas"),
        });

        resize();
      }

      function updateParticles() {
        let i, x, y, z, vx, vy, vz, vw, age, life, p, t;

        for (i = 0; i < particleCount; i++) {
          [age, life] = ages.get(i * ages.spread);

          if (age > life) {
            resetParticle(i);
          } else {
            ([x, y, z] = positions.get(i * positions.spread)),
              ([vx, vy, vz, vw] = velocities.get(i * velocities.spread));

            t =
              simplex.noise4D(x * 0.005, y * 0.005, z * 0.005, time * 0.0005) *
              TAU *
              6;
            p = t * 2;

            vx = lerp(vx, sin(p) * cos(t) * vw, 0.25);
            vy = lerp(vy, sin(p) * sin(t) * vw, 0.25);
            vz = lerp(vz, cos(p) * vw, 0.25);

            x = lerp(x, x + vx, 0.25);
            y = lerp(y, y + vy, 0.25);
            z = lerp(z, z + vz, 0.25);

            positions.set([x, y, z], i * positions.spread);
            velocities.set([vx, vy, vz], i * velocities.spread);
            ages.set([++age], i * ages.spread);
          }
        }
      }

      function render() {
        requestAnimationFrame(render);

        time++;

        updateParticles();

        geometry.attributes.position.needsUpdate = true;
        geometry.attributes.color.needsUpdate = true;
        geometry.attributes.age.needsUpdate = true;

        mesh.rotation.y += 0.003;

        renderer.render(scene, camera);
      }

      function resize() {
        camera.aspect = innerWidth / innerHeight;
        camera.updateProjectionMatrix();

        renderer.setSize(innerWidth, innerHeight);
        renderer.setPixelRatio(devicePixelRatio);
      }
    },
  },
};
</script>

<style scoped>
.controls {
  width: 100%;
  height: 600px;
  position: fixed;
  top: 10%;
  left: 0;
  bottom: 0;
  /* background-color: aquamarine; */
}
.vas {
 
  position: absolute;
  top: 0;
  right: 0;
}
</style>