<template>
  <div>
    <topHeadeg />
    <div class="banan">
      <img src="../../assets/medicalcare/security/banan.png" alt="" />
      <div class="title">
        <p>为客户提供最合适的解决方案</p>
      </div>
    </div>

    <div class="beij">
      <div class="zedrt">
        <div class="tiA"></div>

        <div class="typeface">
          <p>方案介绍</p>
        </div>
        <div class="tiB"></div>
      </div>
      <!-- 标题end -->

      <div
        class="introduce"
        data-aos="fade-up"
        data-aos-once="true"
        data-aos-offset="0"
        data-aos-delay="500"
        data-aos-anchor-placement:="bottom-bottom"
      >
        针对企业、政府单位两方面开展服务，突破传统安全管理模式。已形成稳定，可持续发展的运用模块，帮助企业实现方便、高效的安全管理体系。
      </div>

      <div class="zedrt">
        <div class="tiA"></div>

        <div class="typeface">
          <p>服务特性</p>
        </div>
        <div class="tiB"></div>
      </div>
      <!-- 标题end -->

      <gradually :potty="medica" />
    </div>

    <div style="height: 253px"></div>

    <homeBottom />
  </div>
</template>

<script>
import topHeadeg from "@/components/topHeadeg.vue";
import homeBottom from "@/components/homeBottom.vue";
import gradually from "@/components/product/gradually.vue";
import AOS from "aos";
export default {
  components: {
    topHeadeg,
    homeBottom,
    gradually,
  },

  data() {
    return {
      Select: 1,
      medica: [
        {
          name: "可视化分析",
          img: require("../../assets/medicalcare/security/duwtx1.png"),
        },
        {
          name: "智慧运营",
          img: require("../../assets/medicalcare/security/duwtx2.png"),
        },
        {
          name: "安全规范",
          img: require("../../assets/medicalcare/security/duwtx3.png"),
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
};
</script>

<style scoped lang="less">
.introduce {
  width: 1014px;
  height: 252px;
  margin: 0 auto;
  color: #212121;
  font-size: 20px;
  line-height: 43px;
  text-align: center;
  font-weight: 600;
}

.title > p {
  text-align: center;
}
.title {
  color: #fff;
  font-size: 50px;
  width: 100%;
  position: absolute;
  bottom: 27px;
}
.banan {
  width: 100%;
  position: relative;
}
.banan > img {
  width: 100%;
  height: 100%;
}
//end
.typeface {
  color: #282828;
  font-size: 40px;
  margin: 0 29px;
  font-weight: bold;
}
.zedrt {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 75px;
}
.tiB {
  width: 120px;
  height: 9px;
  background: #0f4f7a;
  border-radius: 6px;
}
.tiA {
  width: 120px;
  height: 9px;
  background: #456e71;
  border-radius: 6px;
}
// 标题
.beij {
  padding-top: 75px;
  width: 100%;
  background: url("../../assets/medicalcare/security/beijp.png") no-repeat
    center center;
  background-size: cover;
}
</style>