<template>
  <div>
    <topHeadeg />
    <div class="banan">
      <img src="../../assets/product/medicalapparatus/banan.png" alt="" />
      <!-- <div class="titie">
        <p class="titie-p">医疗器械经营管理系统</p>
        <p>基于深度学习模型的医疗器械全生命周期管理产品</p>
      </div> -->
    </div>

    <div class="introA">
      <div class="zedrt">
        <div class="tiA"></div>

        <div class="typeface">
          <p>产品特色</p>
        </div>
        <div class="tiB"></div>
      </div>
    </div>

    <gradually :potty="medica" />

    <!-- <div class="parat">
     
    </div> -->
    <div
      class="ecog"
      data-aos="fade-up"
      data-aos-once="true"
      data-aos-offset="0"
      data-aos-delay="0"
      data-aos-anchor-placement:="bottom-bottom"
    >
      <p>
        基于医疗器械机构接入多个厂家、多级经销商，提供全生态的智慧监管平台。统一的数据监控平台为政府监管部门提供精准可靠的数据支持，帮助监管部门发现潜在风险，建造和谐市场环境。
      </p>
    </div>

    <div class="introC">
      <div class="zedrt">
        <div class="tiA"></div>

        <div class="typeface">
          <p>业务概览</p>
        </div>
        <div class="tiB"></div>
      </div>
    </div>

    <div
      class="mdeic"
      data-aos="fade-up"
      data-aos-once="true"
      data-aos-offset="0"
      data-aos-delay="0"
      data-aos-anchor-placement:="bottom-bottom"
    >
      <img src="../../assets/product/medicalapparatus/medic.png" alt="" />
    </div>

    <div class="intro">
      <div class="zedrt">
        <div class="tiA"></div>

        <div class="typeface">
          <p>成功案例</p>
        </div>
        <div class="tiB"></div>
      </div>
    </div>
    <div class="patus">
      <logo :itemimg="items" :distance="0"></logo>
    </div>
    <homeBottom />
  </div>
</template>

<script>
import topHeadeg from "@/components/topHeadeg.vue";
import homeBottom from "@/components/homeBottom.vue";
import logo from "@/components/logo.vue";
import gradually from "@/components/product/gradually.vue";
import AOS from "aos";

export default {
  components: {
    topHeadeg,
    homeBottom,
    logo,
    gradually,
  },
  data() {
    return {
      items: [
        { img: require("../../assets/logo/yilialogo/yilia1.png") },
        { img: require("../../assets/logo/yilialogo/yilia2.png") },
        { img: require("../../assets/logo/yilialogo/yilia3.png") },
        { img: require("../../assets/logo/yilialogo/yilia4.png") },
      ],
      medica: [
        {
          name: "物资管理可视化",
          img: require("../../assets/product/medicalapparatus/duwtx1.png"),
        },
        {
          name: "医疗信息数字化",
          img: require("../../assets/product/medicalapparatus/duwtx2.png"),
        },
        {
          name: "智慧医疗大数据",
          img: require("../../assets/product/medicalapparatus/duwtx3.png"),
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      AOS.init();
    });
  },
};
</script>

<style scoped lang="less">
.introC{
  margin: 90PX 0;
}
.patus {
  margin-top: 60px;
  margin-bottom: 164px;
}
.mdeic img {
  width: 100%;
  height: 100%;
}
.mdeic {
  width: 1400px;
  // height: 660px;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 100px;
}
.ecog p {
  line-height: 44px;
}
.ecog {
  width: 1111px;
  margin: 0 auto;
  padding-top: 124px;
  text-align: center;
  font-size: 20px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 600;
  color: #212121;
}
.parat {
  width: 1170px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.card p:hover {
  background-color: #f44336;
}
.card p {
  width: 100%;
  background-color: #b6b6b6;
  height: 71px;
  color: #fff;
  box-shadow: 0px, 2px, 4px, 0px #000;
  line-height: 71px;
  text-align: center;
  font-size: 20px;
}
.card img {
  width: 100%;
  height: 398px;
}
.card {
  width: 371px;
  font-size: 0;
}
.titie-p {
  font-size: 55px;
  margin-bottom: 30px;
}
.titie {
  position: absolute;
  top: 80px;
  left: 119px;
  color: #fff;
  font-size: 32px;
}
.banan img {
  width: 100%;
  height: 100%;
}
.banan {
  width: 100%;
  height: 808px;

}
.introA {
  padding-top: 5%;
}
.inter {
  margin: 91px 0;
}
//end
.typeface {
  color: #282828;
  font-size: 40px;
  font-weight: 600;
  margin: 0 29px;
}
.zedrt {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 75px 0;
}
.tiB {
  width: 120px;
  height: 9px;
  background: #0f4f7a;
  border-radius: 6px;
}
.tiA {
  width: 120px;
  height: 9px;
  background: #456e71;
  border-radius: 6px;
}
// 标题
</style>