<template>
  <div>
    <topHeadeg />
    <div class="banan">
      <img src="../../assets/product/Facerecognition/banan.png" alt="" />
      <!-- <div class="titie">
        <p class="titie-p">高危行为防控系统</p>
        <p>基于高精度人脸识别和肢体特征的心理安防产品</p>
      </div> -->
    </div>

    <div class="introA">
      <div class="zedrt">
        <div class="tiA"></div>

        <div class="typeface">
          <p>产品特色</p>
        </div>
        <div class="tiB"></div>
      </div>
    </div>

    <div class="hisk">
      <img src="../../assets/product/Facerecognition/facer.png" alt="" />
      <div
        class="cartyu"
        data-aos="fade-up"
        data-aos-once="true"
        data-aos-offset="0"
        data-aos-delay="500"
        data-aos-anchor-placement:="bottom-bottom"
      >
        <div class="card">
          <p class="card-ti">活体检测</p>
          <p>判断是否为真人操作，抵御图片、视频等恶意行为</p>
        </div>
        <div class="card">
          <p class="card-ti">人脸搜索</p>
          <p>对比人脸与人脸库信息、筛选出相似照片，返回相似度</p>
        </div>
        <div class="card">
          <p class="card-ti">实名认证</p>
          <p>对接中盾安信数据源，实现认证的安全性、易用性</p>
        </div>
      </div>
    </div>

    <homeBottom />
  </div>
</template>

<script>
import topHeadeg from "@/components/topHeadeg.vue";
import homeBottom from "@/components/homeBottom.vue";
import AOS from "aos";

export default {
  components: {
    topHeadeg,
    homeBottom,
  },
  data() {
    return {
      banan: "../../assets/product/Facerecognition/banan.png",
    };
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
};
</script>

<style scoped lang="less">
.card-ti {
  font-size: 20px;
  margin-top: 26px;
  margin-bottom: 32px;
}
.cartyu {
  width: 1400px;
  margin: 0 auto;
  margin-top: -79px;
  display: flex;
  justify-content: space-between;
  color: #fff;
  text-align: center;
  font-size: 14px;
}
.hisk > img {
  width: 100%;
  height: 100%;
}
.hisk {
  width: 100%;
  height: 467px;
  margin-bottom: 213px;
}
.card {
  width: 450px;
  height: 137px;
  background-color: #212121;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}
.titie-p {
  font-size: 80px;
  margin-bottom: 50px;
}
.titie {
  width: 100%;
  position: absolute;
  top: 169px;
  color: #fff;
  font-size: 32px;
  text-align: center;
  margin: 0 auto;
}
.banan > img {
  width: 100%;
  height: 100%;
}

.banan {
  width: 100%;
  height: 808px;
}
.inter {
  margin: 97px 0;
}
//end
.typeface {
  color: #282828;
  font-size: 40px;
  font-weight: 600;
  margin: 0 29px;
}
.zedrt {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 75px 0;
}
.tiB {
  width: 120px;
  height: 9px;
  background: #0f4f7a;
  border-radius: 6px;
}
.tiA {
  width: 120px;
  height: 9px;
  background: #456e71;
  border-radius: 6px;
}
// 标题
</style>