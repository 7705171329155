<template>
	<div class="con">

		<main class="container">
			<!-- <h2 data-splitting class="headline headline--jump" v-if="jump!='null'">{{jump}}</h2>
			<h2 data-splitting class="headline headline--float" v-if="float!=undefined ">{{float}}</h2> 
			<h2 data-splitting class="headline headline--jog" v-if="jog!=undefined">{{jog}}</h2>
			<h2 data-splitting class="headline headline--flip" v-if="flip!=undefined">{{flip}}</h2>
			<h2 data-splitting class="headline headline--twirl" v-if="twirl!=undefined">{{twirl}}</h2> -->
			<h2 data-splitting class="headline headline--fall" v-if="fall!=undefined">{{fall}}</h2> 
		</main>

	</div>
</template>

<script>
	/* eslint-disable */
	import ImageCompressor from '../utils/splitting.min.js'
	export default {
		components: {},
			props:['jump','float','jog','flip','twirl','fall'],
		data() {
			return {

			};
		},
		mounted() {
			this.compressImage()
		},
		created() {},
		methods: {
			compressImage() {
				new ImageCompressor()
			},

		},
	};
</script>

<style lang="less">
.con{

	* {
		box-sizing: border-box;
	}

	/* html, body {
   height: 100%;
 } */

	/* .container {
   max-height: 100vh;
   overflow-y: scroll;
   position: relative;
   -ms-scroll-snap-type: y mandatory;
       scroll-snap-type: y mandatory;
   -webkit-overflow-scrolling: touch;
 } */

	.headline {
		align-items: center;
		   background-color: rgba(255,255,255,0.0) !important;
		display: flex;
		font-family: "Lexend Deca", sans-serif;
		font-weight: 800;
		font-size: 16px;
		justify-content: center;
		overflow: hidden;
		perspective: 1000px;
		scroll-snap-align: start;
		width: 160px;
		height: 60px;
	}

	

	.word,
	.char {
		-webkit-animation-delay: var(--del);
		animation-delay: var(--del);
		-webkit-animation-direction: var(--dir, normal);
		animation-direction: var(--dir, normal);
		-webkit-animation-duration: var(--dur);
		animation-duration: var(--dur);
		-webkit-animation-iteration-count: var(--it, infinite);
		animation-iteration-count: var(--it, infinite);
		-webkit-animation-name: var(--name);
		animation-name: var(--name);
		-webkit-animation-timing-function: var(--tf);
		animation-timing-function: var(--tf);
		-webkit-animation-fill-mode: var(--fill, forwards);
		animation-fill-mode: var(--fill, forwards);
		display: inline-block;
		position: relative;
		transform-origin: 50% 100%;
		z-index: 1;
	}

	.headline--fall .char {
		--name: fall;
		--dur: 600ms;
		--del: calc(var(--char-index) * -0.05s);
		--tf: cubic-bezier(0.165, 0.44, 0.64, 1);
	}

	@-webkit-keyframes fall {
		0% {
			transform: rotateY(-25deg);
		}

		25% {
			transform: translateY(2%) rotateY(25deg);
		}

		50% {
			transform: rotateY(-25deg);
		}

		75% {
			transform: translateY(4%) rotateY(25deg);
		}

		100% {
			transform: rotateY(-25deg);
		}
	}

	@keyframes fall {
		0% {
			transform: rotateY(-25deg);
		}

		25% {
			transform: translateY(2%) rotateY(25deg);
		}

		50% {
			transform: rotateY(-25deg);
		}

		75% {
			transform: translateY(4%) rotateY(25deg);
		}

		100% {
			transform: rotateY(-25deg);
		}
	}

	.headline--flip .char {
		--name: flip;
		--dur: 4000ms;
		--del: calc(var(--char-index) * 0.075s);
		--tf: linear;
	}

	@-webkit-keyframes flip {
		5% {
			transform: rotateX(1turn);
		}

		10% {
			transform: rotateX(2turn);
		}

		20% {
			transform: rotateX(3turn);
		}

		40% {
			transform: rotateX(4turn);
		}

		70%,
		100% {
			transform: rotateX(5turn);
		}
	}

	@keyframes flip {
		5% {
			transform: rotateX(1turn);
		}

		10% {
			transform: rotateX(2turn);
		}

		20% {
			transform: rotateX(3turn);
		}

		40% {
			transform: rotateX(4turn);
		}

		70%,
		100% {
			transform: rotateX(5turn);
		}
	}

	.headline--float .char {
		--name: float;
		--dur: 2200ms;
		--del: calc(var(--char-index) * -0.5s);
		--tf: ease-in-out;
		--dir: alternate;
	}

	.headline--float .char:nth-child(2n) {
		--name: float-alt;
	}

	@-webkit-keyframes float {
		from {
			transform: translate(2%, -10%) rotate(-1deg);
		}

		to {
			transform: translate(-2%, 5%) rotate(3deg);
		}
	}

	@keyframes float {
		from {
			transform: translate(2%, -10%) rotate(-1deg);
		}

		to {
			transform: translate(-2%, 5%) rotate(3deg);
		}
	}

	@-webkit-keyframes float-alt {
		from {
			transform: translate(0%, -5%) rotate(-1deg);
		}

		to {
			transform: translate(2%, 10%) rotate(3deg);
		}
	}

	@keyframes float-alt {
		from {
			transform: translate(0%, -5%) rotate(-1deg);
		}

		to {
			transform: translate(2%, 10%) rotate(3deg);
		}
	}

	.headline--jog .char {
		--name: jog;
		--dur: 500ms;
		--del: calc(var(--char-index) * -0.025s);
		--tf: linear;
	}

	@-webkit-keyframes jog {
		0% {
			transform: translate(0, 0) rotate(5deg);
		}

		25% {
			transform: translate(5%, -5%) rotate(10deg);
		}

		50% {
			transform: translate(5%, 0) rotate(15deg);
		}

		75% {
			transform: translate(10%, -5%) rotate(10deg);
		}

		100% {
			transform: translate(0, 0) rotate(5deg);
		}
	}

	@keyframes jog {
		0% {
			transform: translate(0, 0) rotate(5deg);
		}

		25% {
			transform: translate(5%, -5%) rotate(10deg);
		}

		50% {
			transform: translate(5%, 0) rotate(15deg);
		}

		75% {
			transform: translate(10%, -5%) rotate(10deg);
		}

		100% {
			transform: translate(0, 0) rotate(5deg);
		}
	}

	.headline--jump .char {
		--name: jump;
		--dur: 4000ms;
		--del: calc(var(--char-index) * 0.075s);
		--tf: cubic-bezier(0.165, 0.44, 0.64, 1);
	}

	@-webkit-keyframes jump {
		20% {
			transform: translateY(2%) scaleY(0.9);
		}

		40% {
			transform: translateY(-100%) scaleY(1.2);
		}

		50% {
			transform: translateY(10%) scaleY(0.8);
		}

		70% {
			transform: translateY(-5%) scaleY(1);
		}

		80%,
		100% {
			transform: translateY(0) scaleY(1);
		}
	}

	@keyframes jump {
		20% {
			transform: translateY(2%) scaleY(0.9);
		}

		40% {
			transform: translateY(-100%) scaleY(1.2);
		}

		50% {
			transform: translateY(10%) scaleY(0.8);
		}

		70% {
			transform: translateY(-5%) scaleY(1);
		}

		80%,
		100% {
			transform: translateY(0) scaleY(1);
		}
	}

	.headline--twirl .char {
		--name: twirl;
		--dur: 6000ms;
		--del: calc(var(--char-index) * 0.025s);
		--tf: linear;
	}

	@-webkit-keyframes twirl {
		2.5% {
			transform: rotateY(1turn);
		}

		5% {
			transform: rotateY(2turn);
		}

		10% {
			transform: rotateY(3turn);
		}

		20% {
			transform: rotateY(4turn);
		}

		40% {
			transform: rotateY(5turn);
		}

		70%,
		100% {
			transform: rotateY(6turn);
		}
	}

	@keyframes twirl {
		2.5% {
			transform: rotateY(1turn);
		}

		5% {
			transform: rotateY(2turn);
		}

		10% {
			transform: rotateY(3turn);
		}

		20% {
			transform: rotateY(4turn);
		}

		40% {
			transform: rotateY(5turn);
		}

		70%,
		100% {
			transform: rotateY(6turn);
		}
	}

}
	
</style>
