<template>
  <div class="Building">
    <div class="room">
      <p class="with">
        With innovative technology, let everyone release their potential and
        achieve extraordinary
      </p>

      <div class="puto">
        <div class="addre">
          <p>
            Address：Room 1601, Liziyuan Building, No. 4711 Jiaotong Road, Putuo
            District, Shanghai
          </p>
          
          <p>Email：support@aventura.net.cn</p>
          <p>Customer service hotline：4000890369</p>
        </div>

        <img src="../../assets/shanghaigz.jpg" alt="" />
      </div>

      <p class="rigt">Copyright © 2012-2022 ICP 备19038355号-1</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
.rigt {
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  margin-top: 35px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}
@import "../../font-style/English.css";

.with {
  padding-top: 81px;
  font-family: Are-You;
  font-size: 22px;

  font-weight: normal;
}
.puto {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 19px;
}
.puto > img {
  width: 144px;
  height: 144px;
  margin-top: 59px;
}
.addre {
  width: 605px;
  font-size: 14px;
  line-height: 50px;
  margin-top: 19px;
  font-weight: 500;
  font-family: PingFangSC-Medium, PingFang SC;
}
.room {
  width: 1257px;
  height: 393px;
  margin: 0 auto;
  color: #fff;
  font-size: 22px;
  background-color: #040404;

}
.Building {
    width: 100%;
  height: 430px;
  background-color: #040404;
}
</style>