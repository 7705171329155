<template>
  <div>
    <topHeadeg />
    <div class="banan">
      <img src="../assets/product/paytu.png" alt="" />
      <div class="medun">
        <p>非凡科技做心灵卫士</p>
        <p>推动行业发展的创新型科技企业</p>
      </div>
    </div>
    <div class="intro">
      <div class="zedrt">
        <div class="tiA"></div>

        <div class="typeface">
          <p>产品介绍</p>
        </div>
        <div class="tiB"></div>
      </div>
      <div
        class="runicon"
        data-aos="fade-down"
        data-aos-once="true"
        data-aos-offset="0"
        data-aos-delay="300"
        data-aos-anchor-placement:="bottom-bottom"
      >
        <div class="runicon-p">
          <div class="icon-t">
            <div class="icon-bj"></div>
            <img
              src="../assets/product/icon1.png"
              alt=""
            />
          </div>
          <p>医疗器械经营管理系统</p>
        </div>

        <div class="runicon-p">
          <div class="icon-t">
            <div class="icon-bj"></div>
            <img
              src="../assets/product/icon2.png"
              alt=""
            />
          </div>
          <p>角膜接触镜经营管理系统</p>
        </div>

        <div class="runicon-p">
          <div class="icon-t">
            <div class="icon-bj"></div>
            <img
              src="../assets/product/icon3.png"
              alt=""
            />
          </div>
          <p>人脸身份验证</p>
        </div>

        <div class="runicon-p">
          <div class="icon-t">
            <div class="icon-bj"></div>
            <img
              src="../assets/product/icon4.png"
              alt=""
            />
          </div>
          <p>高危行为防控系统</p>
        </div>

        <div class="runicon-p">
          <div class="icon-t">
            <div class="icon-bj"></div>
            <img
              src="../assets/product/icon5.png"
              alt=""
            />
          </div>
          <p>企业智慧安全管理平台</p>
        </div>
      </div>
    </div>

    <div class="medical">
      <div class="medical-img">
        <img src="../assets/product/medical.png" alt="" />
      </div>

      <div
        class="medical-tit"
        data-aos="fade-up"
        data-aos-once="true"
        data-aos-anchor-placement:="bottom-bottom"
        data-aos-offset="200"
        data-aos-delay="300"
      >
        <h1>医疗器械经营管理系统</h1>
        <p>
          通过医疗器械生产、流通、消费中的数据存储、产品溯源、跟踪反馈等环节，实现全程质量记录跟踪可追溯、保障全民的医疗安全，提升医疗质量并降低管理成本。
        </p>

        <div class="but">
          <router-link to="/medicalapparatus">查看详情</router-link>
        </div>
      </div>
    </div>

    <div class="Corneal">
      <div
        class="Corneal-tit"
        data-aos="fade-up"
        data-aos-once="true"
        data-aos-anchor-placement:="bottom-bottom"
        data-aos-offset="200"
        data-aos-delay="300"
      >
        <h1>角膜接触镜经营管理系统</h1>
        <p>
          建立基于区块链技术、物联网技术、RFID技术相结合的管理平台，实现商品与设备追踪，从科研、生产、流动到使用过程的全方位实时监控，开创医疗产品从生产到营销的全链条溯源的新时代。
        </p>
        <div class="but"><router-link to="/Corneal">查看详情</router-link></div>
      </div>

      <div class="Corneal-img">
        <img src="../assets/product/cornea.png" alt="" />
      </div>
    </div>

    <div class="Face">
      <div class="medical-img">
        <img src="../assets/product/Face.png" alt="" />
      </div>

      <div
        class="Face-tit"
        data-aos="fade-up"
        data-aos-once="true"
        data-aos-anchor-placement:="bottom-bottom"
        data-aos-offset="200"
        data-aos-delay="300"
      >
        <h1>人脸身份验证</h1>
        <p>
          采用人脸识别技术，拥有自主可控且不断创新的人工智能核心技术。应用集成人脸识别算法，实现从智能感知到认知、决策技术。
        </p>
        <div class="but">
          <router-link to="/Facerecognition">查看详情</router-link>
        </div>
      </div>
    </div>

    <div class="Corneal">
      <div
        class="Corneal-tit"
        data-aos="fade-up"
        data-aos-once="true"
        data-aos-anchor-placement:="bottom-bottom"
        data-aos-offset="200"
        data-aos-delay="300"
      >
        <h1>高危行为防控系统</h1>
        <p>
          基于人脸、眼动及肢体动作多模态结合的心理疾病识别算法，通过智能筛查、实施监控、长周期分析等技术手段。实现信息融合，减少高危行为的发生。
        </p>
        <div class="but"><router-link to="/highisk">查看详情</router-link></div>
      </div>

      <div class="Corneal-img">
        <img src="../assets/product/high.png" alt="" />
      </div>
    </div>

    <div class="medical">
      <div class="medical-img">
        <img src="../assets/product/enterprise.png" alt="" />
      </div>
      <div
        data-aos="fade-up"
        data-aos-once="true"
        data-aos-anchor-placement:="bottom-bottom"
        data-aos-offset="0"
        data-aos-delay="0"
        class="medical-tit"
      >
        <h1>企业智慧安全管理平台</h1>
        <p>
          以整体提高安全管理建设和人才安全素质培养为出发点，以实现信息化管理为落脚点，推动行业数据互联互通，实现服务效益最大化。
        </p>
        <div class="but">
          <router-link to="/securitymanagement">查看详情</router-link>
        </div>
      </div>
    </div>
    <homeBottom />
  </div>
</template>

<script>
import topHeadeg from "@/components/topHeadeg.vue";
import homeBottom from "@/components/homeBottom.vue";
import AOS from "aos";
export default {
  components: {
    topHeadeg,
    homeBottom,
  },
  data() {
    return {
      style: {},
      opacity: 0,
    };
  },

  methods: {},
  mounted() {
    AOS.init({
      offset: 200,
      duration: 600, //持续时间
      easing: "ease-in-sine",
      delay: 100,
    });
  },
};
</script>

<style scoped lang="less">
h1{
  font-size: 50px;
}
//end
.typeface {
  color: #ffffff;
  font-size: 40px;
  font-weight: 600;
  margin: 0 29px;
}
.zedrt {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 75px 0;
}
.tiB {
  width: 120px;
  height: 9px;
  background: #0f4f7a;
  border-radius: 6px;
}
.tiA {
  width: 120px;
  height: 9px;
  background: #456e71;
  border-radius: 6px;
}
// 标题
a {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
}
.but {
  width: 172px;
  height: 58px;
  background-color: #002b8e;
  border: none;
  margin-top: 97px;
  text-align: center;
  line-height: 48px;
}
.Face-tit {
  color: #fff;
  font-size: 30px;
  p {
    font-size: 26px;
    width: 725px;
    height: 111px;
    margin-top: 26px;
  }
}
.Face {
  height: 800px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #15171d;
}
.Corneal-tit {
  // height: 100%;
  // padding-top: 30%;
  color: #15171d;
  font-size: 30px;
  margin-right: 156px;
  p {
    font-size: 26px;
    width: 725px;
    height: 111px;
    margin-top: 26px;
  }
}
.Corneal-img {
  img {
    width: 600px;
    height: 600px;
  }
}
.Corneal {
  height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.medical-tit {
  // height: 500px;
  color: #15171d;
  font-size: 30px;
  p {
    font-size: 26px;
    width: 725px;
    height: 111px;
    margin-top: 26px;
  }
}
.medical-img {
  margin-right: 156px;
  img {
    width: 600px;
    height: 600px;
  }
}

.medical {
  height: 800px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
}

.icon-bj {
  width: 94px;
  height: 97px;
  background: url("../assets/product/bej.png") no-repeat center center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.runicon-p {
  margin: 0 62px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;

  p {
    color: #ffffff;
    font-size: 18px;
    margin-top: 43px;
  }
}
.icon-t {
  width: auto;
  height: 117px;
  position: relative;

  img {
    width: 61px;
    height: 61px;
    margin: 0 auto;
    line-height: 117px;
    position: absolute;
    top: 0;
    left: 8px;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}
.runicon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 120px;
}
.intro {
  background-color: #030b22;
  padding: 91px 0;
}
/* end */
.Line-h {
  width: 375px;
  height: 1px;
  border: 1px solid #979797;
  background-color: #979797;
}
.titi-p {
  margin-top: 10px;
}

.titi {
  color: #212121;
  font-size: 20px;
  font-weight: 600;
  margin-left: 37px;
}

.line-img > img {
  width: 60px;
  height: 60px;
}

.line-img {
  width: 70px;
  height: 70px;
  border: 1px solid #979797;
  display: flex;
  align-items: center;
  justify-content: center;
}

.zedrt {
  display: flex;
  align-items: center;
}
/* 介绍图 */
.medun {
  position: absolute;
  top: 360px;
  left: 118px;
}
.medun > p {
  color: #fff;
  font-size: 55px;
  position: relative;
  margin-bottom: 25px;
}
.banan>img{
  width: 100%;
  height: 100%;
}
.banan {
  width: 100%;
  height: 898px;
  margin: 0 auto;
}
</style>