<template>
  <div>
    <topHeadeg />
    <div class="banan">
      <img src="../../assets/product/securitymanagement/banan.png" alt="" />
      <!-- <div class="titeie">
              <p class="tite-p">企业<span>智慧安全</span>管理平台 </p>
              <p>面向交通运输局和道路运输企业的数字化安全管理平台</p>
          </div> -->
    </div>

    <div class="intro">
      <div class="zedrt">
        <div class="tiA"></div>

        <div class="typeface">
          <p>产品特色</p>
        </div>
        <div class="tiB"></div>
      </div>
    </div>

    <dir
      class="cardy"
      data-aos="fade-up"
      data-aos-once="true"
      data-aos-offset="0"
      data-aos-delay="0"
      data-aos-anchor-placement:="bottom-bottom"
    >
      <div class="card">
        <img src="../../assets/product/securitymanagement/card1.png" alt="" />
        <p>培训数字化</p>
        <p class="card-p">
          课件实时更新，快速迭代，运用虚拟现实等技术实现高度拟真的运输场景，极大提升安全生产人员处理问题能力。
        </p>
      </div>
      <div class="card">
        <img src="../../assets/product/securitymanagement/card2.png" alt="" />
        <p>监管智能化</p>
        <p class="card-p">
          运用AI技术针对每位安全生产人员从课堂到岗位全流程无死角监管，让学习效果反映到实际工作中。
        </p>
      </div>
      <div class="card">
        <img src="../../assets/product/securitymanagement/card3.png" alt="" />
        <p>系统融合化</p>
        <p class="card-p">
          用云服务和视频技术搭建功能完备的学习平台，与交通运输局对接，为企业提供学习服务，为多方提供学习数据。
        </p>
      </div>
    </dir>

    <div class="intro">
      <div class="zedrt">
        <div class="tiA"></div>

        <div class="typeface">
          <p>场景应用</p>
        </div>
        <div class="tiB"></div>
      </div>
    </div>
    <gradually :potty="medica" />
    <div style="height: 253px"></div>

    <homeBottom />
  </div>
</template>

<script>
import topHeadeg from "@/components/topHeadeg.vue";
import homeBottom from "@/components/homeBottom.vue";
import gradually from "@/components/product/gradually.vue";
import AOS from "aos";

export default {
  components: {
    topHeadeg,
    homeBottom,
    gradually,
  },
  data() {
    return {
      medica: [
        {
          name: "课件拟真",
          img: require("../../assets/product/securitymanagement/duwtx1.png"),
        },
        {
          name: "AI行为分析",
          img: require("../../assets/product/securitymanagement/duwtx2.png"),
        },
        {
          name: "数据互通",
          img: require("../../assets/product/securitymanagement/duwtx3.png"),
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
};
</script>

<style scoped lang="less">
.cardy {
  width: 1170px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-p {
  font-size: 14px !important;
  color: #707070 !important;
  margin-top: 10px;
}
.card p {
  color: #212121;
  font-size: 18px;
}
.card {
  width: 241px;
  text-align: center;
  border: none;
}
.card img {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  margin: 20px auto;
}
.tite-p span {
  font-size: 80px;
}
.tite-p {
  font-size: 55px;
  margin-bottom: 40px;
}
.titeie {
  color: #fff;
  font-size: 32px;
  position: absolute;
  top: 100px;
  left: 278px;
}
.banan img {
  width: 100%;
  height: 100%;
}
.banan {
  width: 100%;
  height: 808px;
}

//end
.typeface {
  color: #282828;
  font-size: 40px;
  font-weight: 600;
  margin: 0 29px;
}
.zedrt {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 107px 0;
}
.tiB {
  width: 120px;
  height: 9px;
  background: #0f4f7a;
  border-radius: 6px;
}
.tiA {
  width: 120px;
  height: 9px;
  background: #456e71;
  border-radius: 6px;
}
// 标题
</style>