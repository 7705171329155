import Vue from 'vue'
import VueRouter from 'vue-router'
// import { component } from 'vue/types/umd'

import AboutView from '../views/AboutView.vue'
import Solution from '../views/Solution.vue'
import product from '../views/product.vue'
import NewsCenter from '../views/NewsCenter.vue'
import Oldhome from '../views/Oldhome.vue'
import home3 from '../views/home3.vue'
import Fallback from '../views/Fallback.vue'
import Introduce from '../views/Introduce.vue'
import Facerecognition from '../views/Featured/Facerecognition.vue'
import medicalapparatus from '../views/Featured/medicalapparatus.vue'
import securitymanagement from '../views/Featured/securitymanagement.vue'
import Corneal from '../views/Featured/Corneal.vue'
import highisk from '../views/Featured/highisk.vue'
import Biological from '../views/Featured/Biological.vue'
import medicalcare from '../views/Solution/medicalcare.vue'
import security from '../views/Solution/security.vue'
import finance from '../views/Solution/finance.vue'
import honor from '../views/concerning/honor.vue'
import Company from '../views/concerning/Company.vue'
import Joius from '../views/concerning/Joius.vue'
import team from '../views/concerning/team.vue'
import Homepage from '../views/English/Homepage.vue'
import Newsdetail from '../views/Newsdetail/index.vue'


Vue.use(VueRouter)

const routes = [{
	path: '/',
	name: 'about',
	component: AboutView,

},
{
	path: '/Homepage',
	name: 'Homepage',
	component: Homepage,
},
{
	path: '/product',
	name: 'product',
	component: product,
},
{
	path: '/solution',
	name: 'Solution',
	component: Solution,
},
{
	path: '/newsCenter',
	name: 'NewsCenter',
	component: NewsCenter,
},
{
	path: '/Oldhome',
	name: 'Oldhome',
	component: Oldhome,
},
{
	path: '/introduce',
	name: 'Introduce',
	component: Introduce,
	redirect: '/Company',
	children: [
		{ path: '/Company', component: Company },
		{ path: '/honor', component: honor },
		{ path: '/Joius', component: Joius },
		{ path: '/team', component: team },
	]
},
{
	path: '/Facerecognition',
	name: 'Facerecognition',
	component: Facerecognition,

},
{
	path: '/medicalapparatus',
	name: 'medicalapparatus',
	component: medicalapparatus,
},
{
	path: '/securitymanagement',
	name: 'securitymanagement',
	component: securitymanagement,
},
{
	path: '/Corneal',
	name: 'Corneal',
	component: Corneal,
},
{
	path: '/medicalcare',
	name: 'medicalcare',
	component: medicalcare,
},
{
	path: '/security',
	name: 'security',
	component: security,
},
{
	path: '/finance',
	name: 'finance',
	component: finance,
},
{
	path: '/highisk',
	name: 'highisk',
	component: highisk,
},
{
	path: '/Biological',
	name: 'Biological',
	component: Biological,
},
{
	path: '/Newsdetail',
	name: 'Newsdetail',
	component: Newsdetail,
},
{
	path: '/home3',
	name: 'home3',
	component: home3,
},
{
	path: '/Fallback',
	name: 'Fallback',
	component: Fallback,
}


	// {
	//   path: '/about',
	//   name: 'about',
	//   // route level code-splitting
	//   // this generates a separate chunk (about.[hash].js) for this route
	//   // which is lazy-loaded when the route is visited.
	//   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
	// }
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch(err => err)
}


const router = new VueRouter({
	routes,
	mode: 'hash',
	scrollBehavior(to, from, savedPosition) {
		return { x: 0, y: 0 }
	},


})

export default router
