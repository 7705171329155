<template>
  <div>
    <topHeadeg @getsondata="getson2" />
    <div class="banan">
      <img
        :src="boxwinde + jiesanbos.pTopImgList[0].url"
        alt=""
        v-if="
          jiesanbos.pTopImgList != null && jiesanbos.pTopImgList.length != 0
        "
      />
    </div>

    <div
      class="beij"
      :style="{ background: 'url(' + loginImage + ')no-repeat center center' }"
    >
      <div class="zedrt">
        <div class="tiA"></div>

        <div class="typeface">
          <p>方案介绍</p>
        </div>
        <div class="tiB"></div>
      </div>
      <!-- 标题end -->

      <div
        class="tite"
        data-aos="fade-up"
        data-aos-once="true"
        data-aos-offset="0"
        data-aos-delay="500"
        data-aos-anchor-placement:="bottom-bottom"
      >
        {{ jiesanbos.pIntroduce }}
      </div>

      <div class="zedrt">
        <div class="tiA"></div>

        <div class="typeface">
          <p>服务特性</p>
        </div>
        <div class="tiB"></div>
      </div>
      <!-- 标题end -->

      <gradually :potty="medica" />
    </div>

    <div style="height: 253px"></div>

    <homeBottom />
  </div>
</template>

<script>
import { solvedetails, featureslist } from "@/api";

import topHeadeg from "@/components/topHeadeg.vue";
import homeBottom from "@/components/homeBottom.vue";
import gradually from "@/components/product/gradually.vue";
import AOS from "aos";

export default {
  components: {
    topHeadeg,
    homeBottom,
    gradually,
  },

  data() {
    return {
      Select: 1,
      boxwinde: process.env.VUE_APP_SRC1,
      medica: [],
      jiesanbos: {},
      loginImage: require("../../assets/medicalcare/calcare/beijp.png"),
    };
  },
  created() {
    console.log(this.$route.query.pNo);
  },

  watch: {
    $route(to, from) {
      if (to.query.pNo != from.query.pNo) {
        // this.craneno = to.params.equipNo; //变量赋值
        this.bindList(); //重新加载数据
      }
    },
  },
  methods: {
    getson2(name) {
      console.log("方式1获得了子类传过来的的值：" + name);
    },
    bindList() {
      console.log("我刷新了");
      this.solvedetails();
    },

    solvedetails() {
      let pno = this.$route.query.pNo;

      solvedetails(pno).then((res) => {
        if (res.code == 200) {
          this.jiesanbos = res.data;
          if (
            res.data.pMiddleImgList != null &&
            res.data.pMiddleImgList.length != "0"
          ) {
            this.loginImage = this.boxwinde + res.data.pMiddleImgList[0].url;
            console.log( this.loginImage,'ssssssss');
          }

          featureslist(this.$route.query.pNo).then((res) => {
            if (res.code == 200) {
              var bosleit = [];
              var zhibos = res.data;
              for (let i = 0; i < zhibos.length; i++) {
                bosleit.push({
                  name: zhibos[i].sName,
                  img: this.boxwinde + zhibos[i].sImgList[0].url,
                });
              }
              console.log(bosleit);
              this.medica = bosleit;
            }
          });
        }
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
    this.solvedetails();
  },
};
</script>

<style scoped lang="less">
.banan > img {
  width: 100%;
  height: 100%;
}
.banan {
  width: 100%;
  height: 369px;
}
.tite {
  width: 1014px;
  height: 252px;
  margin: 0 auto;
  color: #212121;
  font-size: 20px;
  line-height: 43px;
  text-align: center;
  font-weight: 600;
}
//end
.typeface {
  color: #282828;
  font-size: 40px;
  margin: 0 29px;
  font-weight: bold;
}
.zedrt {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 81px;
}
.tiB {
  width: 120px;
  height: 9px;
  background: #0f4f7a;
  border-radius: 6px;
}
.tiA {
  width: 120px;
  height: 9px;
  background: #456e71;
  border-radius: 6px;
}
// 标题
.beij {
  padding-top: 81px;
  width: 100%;
  //background: url("../../assets/medicalcare/calcare/beijp.png") no-repeat center
  //  center;
  background-size: cover !important;
}
</style>
