import request from "@/utils/request";
// 科研合作
export function research(data) {
    return request({
        url: '/portal-api/research/list',
        method: 'get',
        params: data
    })
}
// 关于祺机
export function company(data) {
    return request({
        url: '/portal-api/company/list',
        method: 'get',
        params: data
    })
}
// 导航数据
export function homepage() {
    return request({
        url: '/portal-api/business/module/list',
        method: 'get',
    })
}
// 主营业务
export function Main() {
    return request({
        url: '/portal-api/business/list',
        method: 'get',
    })
}
// 成功案例
export function prose() {
    return request({
        url: '/portal-api/case/list',
        method: 'get',
    })
}
// 新闻列表
export function newslist(data) {
    return request({
        url: '/portal-api/news/trends/list',
        method: 'get',
        params:data
    })
}
// 新闻详情
export function newsdetails(pno) {
    return request({
        url: '/portal-api/news/trends/'+pno,
        method: 'get',
    })
}
// 解决方案详
export function solvedetails(pno) {
    return request({
        url: '/portal-api/solve/plan/'+pno,
        method: 'get',
    })
}
// 服务特性
export function featureslist(pno) {
    return request({
        url: '/portal-api/service/features/list/'+pno,
        method: 'get',
    })
}

// 轮播图
export function rotation() {
    return request({
        url: '/portal-api/home/rotation/list',
        method: 'get',
    })
}