<template>
  <div>
    <topHeadeg />
    <div class="bana">
      <img src="../../assets/product/Biological/banan.png" alt="" />
      <div class="titie">
        <p class="titie-s">体外诊断试剂</p>
        <p class="totoe-p">用于体外定性或定量检测人体心理健康状况的产品</p>
      </div>
    </div>
    <div class="introA">
      <div class="zedrt">
        <div class="tiA"></div>

        <div class="typeface">
          <p>产品特色</p>
        </div>
        <div class="tiB"></div>
      </div>
    </div>
    <div class="calo">
      <img src="../../assets/product/Biological/calo.png" alt="" />
    </div>

    <div
      class="ecog"
      data-aos="fade-up"
      data-aos-once="true"
      data-aos-offset="0"
      data-aos-delay="0"
      data-aos-anchor-placement:="bottom-bottom"
    >
      <p>
        本产品通过体外定性或定量检测人体唾液样本中皮质醇、睾酮、T3、T4、孕酮、去甲肾上腺素等成分的含量，可以便捷、快速、精准的诊断人体心理健康状况。在抑郁症疾病的预防、诊断、治疗监测、愈后观察等方面都有十分重要的意义。
      </p>
    </div>

    <div class="business">
      <div class="introC">
        <div class="zedrt">
          <div class="tiA"></div>

          <div class="typeface">
            <p>业务概览/场景应用/产品优势</p>
          </div>
          <div class="tiB"></div>
        </div>
      </div>

      <div class="hexagon">
        <img src="../../assets/product/Biological/box.png" alt="" />

        <div class="overview">
          <h1>业务概览</h1>
          <div class="biaxin" ></div>

          <p>
            祺机（北京）生物技术有限公司主营业务为体外诊断试剂研发，生产及销售，包括化学发光、免疫荧光等产品
          </p>
        </div>

        <div class="scene">
          <h1>场景应用</h1>
          <div class="biaxin" style=" border: 3px solid #6EA5FE;"></div>


          <p>主要应用于各类医疗机构，未来家庭用诊断试剂将会得到蓬勃发展</p>
        </div>
        <div class="scene2">
          <h1>产品优势</h1>
          <div class="biaxin" style=" border: 3px solid #6EA5FE;"></div>

          <p>便捷、快速、准确、无痛</p>
        </div>
      </div>
    </div>

    <homeBottom />
  </div>
</template>

<script>
import topHeadeg from "@/components/topHeadeg.vue";
import homeBottom from "@/components/homeBottom.vue";
import AOS from "aos";

export default {
  components: {
    topHeadeg,
    homeBottom,
  },
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
};
</script>

<style scoped lang="less">
.scene2 {
  width: 394px;
  height: 394px;
  text-align: center;
  position: absolute;
  right: 14%;
  bottom: -4%;
  h1 {
    font-size: 40px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #74a9fe;
    margin-bottom: 20px;
  }
  p {
    width: 313px;
    height: 103px;
    font-size: 20px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #7b7b7b;
    line-height: 44px;
    margin: 0 auto;
  }
}
.scene {
  width: 394px;
  height: 394px;
  text-align: center;
  position: absolute;
  left: 14%;
  right: 0;
  bottom: -2%;
  h1 {
    font-size: 40px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #74a9fe;
    margin-bottom: 20px;

  }
  p {
    width: 313px;
    height: 103px;
    font-size: 20px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #7b7b7b;
    line-height: 44px;
    margin: 0 auto;
  }
}
.overview > p {
  width: 313px;
  height: 192px;
  font-size: 20px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #ffffff;
  line-height: 30px;
  margin: 0 auto;
}
.biaxin {
  width: 91px;
  height: 1px;
  border: 3px solid #ffffff;
  border-radius: 10px;
  margin: 18px auto;
}
.overview > h1 {
  font-size: 40px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #ffffff;
}
.overview {
  width: 394px;
  height: 394px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 19%;
  margin: auto;
}
.hexagon > img {
  width: 100%;
}

.hexagon {
  background-color: #fcfcfd;
  position: relative;
}

.business {
  background-color: #fcfcfd;
}
.calo > img {
  width: 100%;
  height: 467px;
}
.menf-img img {
  width: 60px !important;
  height: 60px;
  line-height: 60px;
}
.logo {
  margin-bottom: 133px;
}
.cornt {
  width: 1500px;
  margin: 0 auto;
  padding-top: 61px;
  display: flex;
  justify-content: space-between;
}
.tages {
  display: flex;
  align-items: center;
}
.tages span {
  color: #fff;
  font-size: 27px;
  margin-left: 50px;
}
.tages img {
  width: 80px !important;
  height: 80px;
}
.neal p {
  margin-bottom: 10px;
}
.neal {
  width: 210px;
  margin: 0 auto;
  text-align: center;
  color: #ffffff;
  font-size: 20px;
}
.menf-img {
  width: 70px;
  height: 70px;
  border: 1px solid #979797;
  margin: 0 auto;
  margin-bottom: 27px;
}
.xiam {
  width: 1px;
  height: 43px;
  border: 1px solid #979797;
  background-color: #979797;
  margin: 0 auto;
}
.meng {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  // background-color: rgba(0, 0, 0, 0.5);
}
.rnel > img {
  width: 100%;
  height: 533px;
}
.rnel {
  width: 100%;
  height: 533px;
  position: relative;
}
.ecog p {
  line-height: 30px;
  font-weight: 500;
}
.ecog {
  width: 1111px;
  height: 180px;
  margin: 0 auto;
  padding: 53px 0;
  color: #212121;
  font-size: 20px;
  text-align: center;
}
//car end
.ement-u {
  width: 1170px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 53px;
}
.ement p:hover {
  background-color: #f44336;
}
.ement p {
  height: 71px;
  background-color: #b6b6b6;
  color: #fff;
  line-height: 71px;
  text-align: center;
  font-size: 20px;
}
.ement img {
  width: 100%;
  height: 398px;
}
.ement {
  width: 371px;
  font-size: 0;
}
// car
.totoe-p {
  width: auto;
  height: 75px;
  color: #fff;
  font-size: 30px;
  line-height: 75px;
  text-align: center;
  margin-top: 51px;
}

.titie-s {
  font-size: 85px;
  color: #ffffff;
}
.titie {
  position: absolute;
  bottom: 340px;
  left: 191px;
}
.bana img {
  width: 100%;
  height: 100%;
}
.bana {
  width: 100%;
  height: 808px;
  position: relative;
}
.inter {
  margin: 91px 0;
}
//end
.typeface {
  color: #282828;
  font-size: 40px;
  font-weight: 600;
  margin: 0 29px;
}
.zedrt {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 75px 0;
}
.tiB {
  width: 128px;
  height: 11px;
  background: #0f4f7a;
  border-radius: 6px;
}
.tiA {
  width: 128px;
  height: 11px;
  background: #456e71;
  border-radius: 6px;
}
// 标题
</style>