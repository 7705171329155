<template>
  <div>
    <topHeadeg />
    <div class="banaa">
<!--      <img src="../assets/Introduce/banan.png" alt="" />-->
      <img :src="bgcImg" alt="">
    </div>

    <div class="option">
      <div><router-link to="/Company">公司历程</router-link></div>
      <div><router-link to="/honor">公司荣誉</router-link></div>
      <div><router-link to="/team">公司团队</router-link></div>
      <div><router-link to="/Joius">加入我们</router-link></div>
    </div>
    <router-view></router-view>
    <homeBottom />
  </div>
</template>

<script>
import topHeadeg from "@/components/topHeadeg.vue";
import homeBottom from "@/components/homeBottom.vue";

export default {
  components: {
    topHeadeg,
    homeBottom,
  },
  data(){
    return{
      bgcImg: ''
    }
  },
  mounted() {
    var bosdata = JSON.parse(localStorage.getItem('accessTop'))
    if (bosdata != null) {
      if(bosdata.mPictureList != null && bosdata.mPictureList.length != '0' && bosdata.mNo == "M20220506000031"){
        this.bgcImg = process.env.VUE_APP_SRC1 + bosdata.mPictureList[0].url
      }
      // 本地存储里面的数据是字符串格式的，但我们需要的是对象格式
    } else {
      return [];
    }
  }
};
</script>

<style scoped lang="less">
.router-link-active {
  display: block;
  width: 129px;
  height: 43px;
  background-color: #f44336;
  margin-top: -1.5px;
}

a {
  text-decoration: none;
  color: #fff;
}
.ptite3 {
  color: #6b4f50 !important;
}
.ptite2 {
  color: #09aff5 !important;
}

.ptite,
.ptite2,
.ptite3 {
  color: #de6a57;
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 600;
}
.tite > p {
  color: #5c5c5c;
  font-size: 14px;
  margin-bottom: 20px;
}
.tite-l {
  padding-right: 30px;
  margin-top: 163px;
}
.tite-i {
  margin-left: 51px;
  margin-top: 164px;
}

.hen {
  display: inline-block;
  width: 48px;
  height: 1px;
  border: 5px solid #979797;
  background-color: #979797;
}
.Line {
  width: 1px;
  height: 1163px;
  border: 5px solid #979797;
  background-color: #979797;
}
.Chart {
  width: 60%;
  margin: 0 auto;
}
.troduce {
  width: 60%;
  height: 106px;
  color: #212121;
  font-size: 14px;
  margin: 0 auto;

  p {
    margin-bottom: 15px;
    text-align: left;
  }
}
.inter {
  margin-top: 81px;
  margin-bottom: 67px;
}

.option {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -45px;
  div {
    width: 129px;
    height: 43px;
    text-align: center;
    line-height: 43px;
    color: #fff;
    font-size: 14px;
  }
}

.banaa img {
  width: 100%;
  height: 100%;
}
</style>
