<template>
  <div>
    <topHeadeg />
    <div class="topis">
      <p class="titi">{{ bosdata.nTitle }}</p>
      <p class="time">发布时间：{{ bosdata.nTime }}</p>
      <p class="xian"></p>

      <div class="editor ql-container ql-snow" style="border: none">
        <div class="ql-editor" v-html="bosdata.nContent"></div>
      </div>
    </div>

    <div style="height: 120px"></div>
    <homeBottom />
  </div>
</template>

<script>
import { newsdetails } from "@/api";

import topHeadeg from "@/components/topHeadeg.vue";
import homeBottom from "@/components/homeBottom.vue";
export default {
  components: {
    topHeadeg,
    homeBottom,
  },
  data() {
    return {
      bosdata: {},
    };
  },
  mounted() {
    let poi = this.$route.query.nNo;
    newsdetails(poi).then((res) => {
      if (res.code == 200) {
        this.bosdata = res.data;
      }
    });
  },
  methods: {},
};
</script>

<style>
@import "../../css/quill.core.css";
@import "../../css/quill.snow.css";
@import "../../css/quill.bubble.css";
</style>
<style scoped lang="less">
.imgty > img {
  width: 100%;
  height: 100%;
}
.imgty {
  width: 100%;
  height: 387px;
}
.hietA {
  margin-bottom: 25px;
}
.jiet {
  margin-top: 56px;
  color: #5a5a5a;
  font-size: 20px;
  margin-bottom: 25px;
}
.xian {
  width: 100%;
  height: 1px;
  border: 1px solid #979797;
}
.time {
  color: #5a5a5a;
  font-size: 20px;
  margin: 55px 0;
}
.titi {
  color: #333333;
  font-size: 30px;
}
.topis {
  width: 1300px;
  margin: 0 auto;
  margin-top: 161px;
}
</style>
