<template>
  <div>
    <div class="outside">
<!--      <img src="../../assets/Introduce/belli.png" alt="" />-->
      <img :src="imgUrl" alt="" />

      <div class="location">
        <div class="intro">
          <div class="zedrt">
            <div class="tiA"></div>

            <div class="typeface">
              <p>{{ companyData.copyTitle }}</p>
            </div>
            <div class="tiB"></div>
          </div>
        </div>

        <div
          class="troduce"
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-offset="0"
          data-aos-delay="0"
          data-aos-anchor-placement:="bottom-bottom"
        >
          <p>{{companyData.copywritingDescription}}</p>
<!--          <p>-->
<!--            祺机（上海）科技股份有限公司成立于2012年，是一家高新技术及双软认定企业。-->
<!--          </p>-->
<!--          <p>-->
<!--            公司的产品及服务主要集中于医疗领域、AI诊疗和生物制剂等领域，服务的政府机构和企业客户遍布全国。-->
<!--          </p>-->
        </div>

        <div class="Chart">
          <div class="chart-wai">
            <div class="zuo">
              <div class="tite-p">
                <div
                  class="tite-l"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-offset="0"
                  data-aos-delay="300"
                  data-aos-anchor-placement:="bottom-bottom"
                >
                  <div class="tite">
                    <h2 class="ptite3">未来：人工智能和生物制剂的专业机构</h2>
                    <p>
                      依托多年行业经验和技术积累，建立统一的人工智能平台和数据中心，为未来的医疗器械全生命周期管理提供专业支持。
                    </p>
                    <p>
                      根据国家十四五规划和2035年远景目标，公司立足于新一代人工智能、脑科学与类脑研究及基因与生物技术领域，结合在医疗和教育行业的技术储备及课题研究，
                      进行AI算法、计算架构与应用场景的融合创新，打造“软硬一体的AI大生产平台”。
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="ine-zuo"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-offset="0"
                data-aos-delay="300"
                data-aos-anchor-placement:="bottom-bottom"
              >
                <span class="titme2">2016-2021</span><span class="hen"></span>
              </div>

              <div
                class="tite"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-offset="0"
                data-aos-delay="300"
                data-aos-anchor-placement:="bottom-bottom"
              >
                <h2 class="ptite">初创：立足教育，专注企业咨询培训</h2>
                <p>
                  为微软、甲骨文、欧特克、思科等跨国公司提供专业的咨询培训服务。
                </p>
                <p>
                  按工信部标准设计出国内第一套“软件资产管理”课程，并提供认证服务。
                </p>
                <p>
                  率先提出：以练为主，以自我发现为主，以业绩为主，优化渠道和提升全员能力的“三优一重”的员工技能实训模式，替代传统培训。
                </p>
              </div>
            </div>

            <!-- 竖线 -->
            <div class="Line"></div>
            <!-- 竖线end -->

            <div
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-offset="0"
              data-aos-delay="300"
              data-aos-anchor-placement:="bottom-bottom"
            >
              <div class="ine-img">
                <span class="hen"></span><span class="titme">2022+</span>
              </div>

              <div
                class="tite-i"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-offset="0"
                data-aos-delay="300"
                data-aos-anchor-placement:="bottom-bottom"
              >
                <div class="tite">
                  <h2 class="ptite2">转型：医疗、教育行业的信息化服务商</h2>
                  <p>
                    用多年积累的方法论，辅以互联网技术，为医疗和教育的用户提供行业解决方案。
                  </p>
                  <p>
                    率先研发出医疗器械经营管理系统，结合云计算和大数据分析，初步实现了医疗器械全生命周期管理，2016年被评为上海市亮点工程。
                  </p>
                </div>
              </div>

              <div
                class="ine-ter"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-offset="0"
                data-aos-delay="300"
                data-aos-anchor-placement:="bottom-bottom"
              >
                <span class="hen"></span><span class="titme3">2012-2016</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AOS from "aos";
import {company} from "@/api";
export default {
  data(){
    return{
      companyData: {},
      imgUrl: "",
      queryForm:{
        currentPage: 1,
        pageSize: 10,
        isEnable: -1
      },
    }
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
    company(this.queryForm).then(res => {
      this.companyData = res.data[0]
      this.imgUrl = process.env.VUE_APP_SRC1+res.data[0].list[0].url
    })
  },
};
</script>

<style scoped lang="less">
.location {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.outside {
  width: 100%;
  height: 1730px;
  position: relative;
}
.outside > img {
  width: 100%;
  height: 700px;
}
.intro {
  margin-top: 76px;
}
//end
.typeface {
  color: #282828;
  font-size: 40px;
  margin: 0 29px;
}
.zedrt {
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-bottom: 76px;
}
.tiB {
  width: 120px;
  height: 9px;
  background: #0f4f7a;
  border-radius: 6px;
}
.tiA {
  width: 120px;
  height: 9px;
  background: #456e71;
  border-radius: 6px;
}
// 标题
.ptite3 {
  color: #de6a57 !important;
}
.ptite2 {
  color: #09aff5 !important;
}

.ptite,
.ptite2,
.ptite3 {
  color: #6b4f50;
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 600;
}
.tite > p {
  color: #5c5c5c;
  font-size: 14px;
  margin-bottom: 20px;
}
.tite-l {
  padding-right: 30px;
  margin-top: 163px;
}
.tite-i {
  margin-left: 51px;
  margin-top: 164px;
}
.tite-p {
  padding-right: 30px;
  margin-top: 104px;
}
.tite {
  width: 502px;
  height: 190px;
  margin-top: 99px;
}
.chart-wai {
  display: flex;
  // align-items: center;
  justify-content: center;
}
.ine-zuo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 160px;
}
.ine-ter {
  display: flex;
  align-items: center;
  margin-top: 163px;
}

.ine-img {
  display: flex;
  align-items: center;
  margin-top: 71px;
}
.titme3 {
  display: block;
  width: 256px;
  height: 289px;
  background: url("../../assets/Introduce/liub2.png") no-repeat center center;
  background-size: cover;
  color: #fff;
  text-align: center;
  line-height: 289px;
  font-size: 20px;
}
.titme2 {
  display: block;
  width: 256px;
  height: 289px;
  background: url("../../assets/Introduce/liub1.png") no-repeat center center;
  background-size: cover;
  color: #fff;
  text-align: center;
  line-height: 289px;
  font-size: 20px;
}
.titme {
  display: block;
  width: 256px;
  height: 289px;
  background: url("../../assets/Introduce/liub.png") no-repeat center center;
  background-size: cover;
  color: #fff;
  text-align: center;
  line-height: 289px;
  font-size: 20px;
}
.hen {
  display: inline-block;
  width: 48px;
  height: 1px;
  border: 2px solid #236d94;
  background-color: #236d94;
}
.Line {
  width: 1px;
  height: 1163px;
  border: 2px solid #236d94;
  background-color: #236d94;
}
.Chart {
  width: 60%;
  margin: 0 auto;
  margin-top: 108px;
}
.troduce {
  width: 60%;
  height: 106px;
  color: #212121;
  font-size: 14px;
  margin: 0 auto;
  padding: 76px 0;
  text-align: center;
  line-height: 50px;
  p {
    margin-bottom: 15px;
    text-align: center;
    font-weight: 600;
  }
}
</style>
