<template>
  <div>
    <div class="medcal">
      <div
        :class="Select == index ? 'Selected' : ''"
        v-for="(item, index) in potty"
        :key="index"
        @mouseover="Safin(index)"
      >
        <div class="atues">
          <img :src="item.img" alt="" />
        </div>
        <p class="parts">{{ item.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["potty"],

  data() {
    return {
      Select: 1,
    };
  },
  methods: {
    Safin(index) {
      this.Select = index;
    },
  },
};
</script>


<style scoped lang="less">
.Selected {
  transform: scale(1.2);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.26);
  p {
    background-color: #f44336;
  }
}
.medcal {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 130px;
}
.atues {
  width: 410px;
  height: 440px;
  font-size: 0;
  overflow: hidden;
}
.atues > img {
  width: 415px;
  height: 445px;
  float: left;
}
.parts {
  width: 410px;
  height: 71px;
  background: #001850;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.26);
  color: #fff;
  font-size: 20px;
  text-align: center;
  line-height: 71px;
  margin-top: -2px;
  font-weight: bold;
  font-size: 26px;
}

.tite {
  width: 1014px;
  height: 252px;
  margin: 0 auto;
  color: #212121;
  font-size: 20px;
  line-height: 43px;
  text-align: center;
  font-weight: 500;
}
</style>