import axios from 'axios'
import { Notification, Message } from 'element-ui'
// 创建axios实例
const service = axios.create({
  // baseURL: process.env.VUE_APP_SRC1,
  baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_SRC1 : process.env.VUE_APP_BASE_API1,   //区分是开发环境还是生产环境

  
  timeout: 10000
})
// request拦截器
service.interceptors.request.use(config => {
  return config
}, error => {
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
     if (code !== 200) {
      Notification.error({
        title: res.data.msg
      })
      return Promise.reject('error')
    } else {
      return res.data
    }
  },
  error => {
    console.log('err' + error)
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    }
    else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    }
    else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
