<template>
  <div>
    <topHeadeg />
    <div class="banan">
      <img :src="imgbanner" alt="" />

      <!-- <div class="think-wa">
        <div
          class="think"
        >
          <p>
            {{ description }}
          </p>
        </div>
      </div> -->
    </div>
    <!-- 新的开始、 -->
    <div class="that">
      <div
        data-aos="fade-up"
        data-aos-once="true"
        data-aos-anchor-placement:="bottom-bottom"
        data-aos-offset="200"
        data-aos-delay="300"
      >
        <div class="tiop">
          <img src="../assets/Scientific/title.png" alt="" />
        </div>

        <div class="shuomi">
          {{ description }}
        </div>

        <div class="stio">
          <img src="../assets/Scientific/soti1.png" alt="" />
          <div class="topae">
            <p class="bachg">人工智能</p>
            <p class="host">
              祺机正致力于开拓人工智能技术应用场景，我们借助计算机领域的人工智能算法和技术，构建人脸、眼动及肢体动作多模态结合的心理健康筛查算法模型，对大样本人群的心理健康情况做出预判。
            </p>
          </div>
        </div>

        <div class="stio">
          <img src="../assets/Scientific/soti2.png" alt="" />
          <div class="topae">
            <p class="bachg">生物试剂</p>
            <p class="host">
              祺机正在建设生物试剂研发团队，研究针对临床诊断试剂、免疫试剂等多种试剂。
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- end  -->

    <div class="mechanism">
      <div class="zedrt">
        <div class="tiA"></div>
        <div class="typeface">
          <p>合作机构</p>
        </div>
        <div class="tiB"></div>
      </div>

      <div
        class="logo"
        data-aos="fade-up"
        data-aos-once="true"
        data-aos-anchor-placement:="bottom-bottom"
        data-aos-offset="200"
        data-aos-delay="300"
      >
        <div
          :class="current == index ? 'logowaiB' : 'logowai'"
          v-for="(item, index) in logo"
          :key="index"
        >
          <img
            :src="item.img"
            class="iteming"
            @mouseover="Select(index)"
            alt=""
          />
          <!-- <el-image

            fit="contain"
            :src="item.img"
          ></el-image> -->
        </div>
      </div>
    </div>

    <!-- <div style="height: 116px"></div> -->
    <homeBottom />
  </div>
</template>

<script>
import topHeadeg from "@/components/topHeadeg.vue";
import homeBottom from "@/components/homeBottom.vue";
import AOS from "aos";
import { research } from "@/api";

export default {
  components: {
    topHeadeg,
    homeBottom,
  },
  data() {
    return {
      current: 0,
      queryForm: {
        currentPage: 1,
        pageSize: 10,
        isShow: -1,
      },
      logo: [
        // { img: require("../assets/Scientific/logo1.png") },
        // { img: require("../assets/Scientific/logo2.png") },
        // { img: require("../assets/Scientific/logo3.png") },
        // { img: require("../assets/Scientific/logo4.png") },
      ],
      description: "",
      imgbanner: "",
    };
  },
  methods: {
    Select(index) {
      this.current = index;
    },
  },
  mounted() {
    var bosdata = JSON.parse(localStorage.getItem("accessTop"));
    if (bosdata != null) {
      if (
        bosdata.mPictureList != null &&
        bosdata.mPictureList.length != "0" &&
        bosdata.mNo == "M20220429000016"
      ) {
        this.imgbanner =
          process.env.VUE_APP_SRC1 + bosdata.mPictureList[0].url;
        this.description = bosdata.mDescribe;
      }
      // 本地存储里面的数据是字符串格式的，但我们需要的是对象格式
    } else {
      return [];
    }

    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      AOS.init();
    });
    research(this.queryForm).then((res) => {
      this.logo = [];
      res.data.forEach((item) => {
        if (item.isShow === 1) {
          this.logo.push({
            img: process.env.VUE_APP_SRC1 + item.list[0].url,
          });
        }
      });
    });
  },
};
</script>

<style scoped lang="less">
.host {
  width: 555px;
  height: 166px;
  font-size: 22px;
  font-weight: 300;
  color: #292c2c;
  line-height: 37px;
  margin-top: 62px;
  margin-left: 68px;
  text-align: left;
}
.bachg {
  width: 168px;
  height: 42px;
  font-size: 42px;
  font-weight: 500;
  color: #292c2c;
  line-height: 63px;
  margin-left: 68px;
  text-align: left;
  margin-top: 90px;
}
.stio {
  position: relative;
  margin-bottom: 90px;
}
.topae {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;

  width: 683px;
  height: 483px;
  background: #d5d4d4;
  opacity: 0.7;
}
.stio > img {
  width: 100%;
  height: 742px;
}
.shuomi {
  width: 708px;
  height: 106px;
  font-size: 20px;
  font-weight: 500;
  color: #8e8e8e;
  line-height: 36px;
  margin: 24px auto;
}
.tiop {
  width: 815px;
  height: 41px;
  margin: 0 auto;
}
.tiop > img {
  width: 100%;
  height: 100%;
}
.that {
  width: 1601px;
  text-align: center;
  margin: 0 auto;
  margin-top: 62px;
}
.logo {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.iteming {
  width: 500px;
  height: 500px;
  position: absolute;
  top: -157px;
  left: 20px;
}
.logowaiB {
  width: 539px;
  height: 188px;
  margin-bottom: 193px;
  background: linear-gradient(90deg, #08308d 0%, #060b36 100%);
  position: relative;
}

.logowai {
  width: 539px;
  height: 188px;
  margin-bottom: 193px;
  position: relative;
}
.mechanism {
  padding-top: 91px;
  width: 100%;
  background: #030b22;
}
.think-wa {
  width: 100%;
  height: 253px;
  position: absolute;
  top: 61%;
}
.think {
  width: 1014px;
  height: 188px;
  margin: 0 auto;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  color: #c6cfd7;
  line-height: 44px;
  padding: 33px 0;
}
.banan > img {
  width: 100%;
  height: 100%;
}
.banan {
  width: 100%;
  position: relative;
}
//end
.typeface {
  color: #ffffff;
  font-size: 40px;
  margin: 0 29px;
}
.zedrt {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 81px;
}
.tiB {
  width: 120px;
  height: 9px;
  background: #0f4f7a;
  border-radius: 6px;
}
.tiA {
  width: 120px;
  height: 9px;
  background: #456e71;
  border-radius: 6px;
}
// 标题
</style>
