<template>
  <div class="home">
    <div class="box" @mouseout="out" @mouseover="over" >
       
        <img
        v-for="(item, index) in imgurl"
        v-show="listIndex === index"
        :key="index"
        :src="item"
        alt=""
      />
     
        <div>

      <div
      :class="res.rotaPosition == 'right' ? 'content2' : 'content'"
        v-show="index == listIndex"
        v-for="(res, index) in list"
        :key="index"
      >
        <h1>{{ res.rotaTitle }}</h1>
        <div class="tents">
          {{ res.rotaDescribe }}
        </div>
      </div>
    </div>

      <!-- <p class="left" @click="changePage(prevIndex)">&lt;</p> -->

      <div class="number">
        <span
          :class="{ color: index == listIndex }"
          v-for="(item, index) in list"
          @click="changePage(index)"
          :key="index"
        >
          {{ "0" + (index + 1) }}
        </span>
      </div>

      <ul>
        <li
          :class="{ color: index == listIndex }"
          v-for="(item, index) in list"
          @click="changePage(index)"
          :key="index"
        ></li>
      </ul>
      <!-- <p class="right" @click="changePage(nextIndex)">&gt;</p> -->
    </div>
  </div>
</template>
   
  <script>
import { rotation } from "@/api";

export default {
  components: {},
  props: {},
  data() {
    return {
      imgurl:[],
      // boxwinde: window.location.origin,
      boxwinde:  process.env.VUE_APP_SRC1,

      list: [
        {
          imgUrl: require("../assets/Rotation/tion1.png"),
          tite: "杭州银行",
          content:
            "祺机科技作为旷视科技的服务供应商，为杭州银行提供优质的产品服务和技术支持，通过定期的巡检和快速的响应服务及时发现问题并解决，以保证杭州银行的人脸识别软件运行更顺畅、更稳定，确保用户有良好的使用体验。",
        },
        {
          imgUrl: require("../assets/Rotation/tion2.png"),
          tite: "绵阳市商业银行",
          content:
            "为了规避金融风险，提升封控技术手段，祺机科技作为旷视科技的服务供应商，为绵阳银行的人脸识别系统进行升级及灾备部署，有效提升活体防攻击能力，进一步加强客户身份识别管理，保障用户资金安全，降低用户金融风险，营造良好金融生态。",
        },
        {
          imgUrl: require("../assets/Rotation/tion3.png"),
          tite: "上海普延医疗器械有限公司",
          content:
            "我们公司为上海普延医疗器械有限公司提供专业的医疗器械管理软件。通过实地调研，我们发现客户在使用之前面临入库量大、验收工作量高的问题，特别需要一款能提供扫码枪入库、自行编辑编码的系统。我们在提供的软件中完善了客户的经营需求，提高了进出库流转率，节省了大量人工。我们的软件具有高度定制化和可扩展性，能满足不同类型和规模的医疗器械企业的需求。",
        },
        {
          imgUrl: require("../assets/Rotation/tion4.png"),
          tite: "角膜接触镜零售远程监管系统",
          content:
            "我司开发角膜接触镜零售远程监管系统配合市药监局以“三个强化”做好医疗器械监管工作，并在上海市静安区上海黄浦区辖区内的角膜接触镜经营企业开展了系统使用试点工作。",
        },
        {
          imgUrl: require("../assets/Rotation/tion5.png"),
          tite: "融资租赁(医疗器械)管理平台",
          content:
            "祺机科技打造的智能管理平台“融资租赁（医疗器械）管理平台”，在平安点创、三井住友、国药控股、海通恒信、海尔融资、君创国际、远东国际等融资租赁医疗器械龙头企业落地后，帮助企业更好避免了融资租赁医疗器械这个行业管理上的多重困境，如管理模式单一、项目信息片面、关联信息缺失、验收信息缺失等。不仅可以满足金融监管要求，还能符合医疗器械监管的需求，有效提升企业的工作效率，节约人力成本，使企业更加灵活和高效地管理业务。",
        },
      ],
      listIndex: 0, //默认显示第几张图片
      timer: null, //定时器
    };
  },
  mounted() {
    this.listdata();
  },
  computed: {
    
    //上一张
    prevIndex() {
      if (this.listIndex == 0) {
        return this.list.length - 1;
      } else {
        return this.listIndex - 1;
      }
    },
    //下一张
    nextIndex() {
      if (this.listIndex == this.list.length - 1) {
        return 0;
      } else {
        return this.listIndex + 1;
      }
    },
  },
 
  methods: {
    listdata(){
      rotation().then((res) => {
        this.list = res.data;
        this.list.forEach((res) => {
          let img = process.env.VUE_APP_SRC1 + res.rpictureList[0].url;
          this.imgurl.push(img) 
        });
      });
    },
    changePage(index) {
      this.listIndex = index;
    },
    //移除
    out() {
      this.setTimer();
    },
    //移入
    over() {
      clearInterval(this.timer);
    },
    //1秒切图
    setTimer() {
      this.timer = setInterval(() => {
        this.listIndex++;
        if (this.listIndex == this.list.length) {
          this.listIndex = 0;
        }
      }, 2500);
    },
  },
  created() {
    //定时器
    this.setTimer();
  },
};
</script>
  <style scoped lang="less">
.content2 {
  position: absolute;
  top: 79px;
  right: 70px;
  color: #fff;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  h1 {
    font-size: 50px;
    font-weight: 800;
    text-align: right;
    margin-bottom: 10px;

  }
  .tents {
    width: 689px;
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    line-height: 50px;
  }
}

.content {
  position: absolute;
  top: 79px;
  left: 117px;
  color: #fff;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  h1 {
    font-size: 50px;
    font-weight: 800;
    margin-bottom: 10px;

  }
  .tents {
    width: 440px;
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    line-height: 50px;
  }
}
.number {
  width: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  top: 85%;
  right: 4%;
  color: #fff;
  font-size: 30px;
  .color {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
  }
  span {
    font-size: 20px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #979797;
    cursor: pointer;
    width: 101px;
    height: 1px;
    text-align: center;
  }
}
.home {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .box {
    position: relative;
    width: 100%;
    height: 593px;
    img {
      width: 100%;
      height: 100%;
      z-index: 100;
    }
    p {
      cursor: pointer;
      color: white;
      font-size: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      background: rgba(0, 0, 0, 0.5);
    }
    .left {
      position: absolute;
      top: 50%;
      left: 0;
    }
    .right {
      position: absolute;
      top: 50%;
      right: 0;
    }
    ul {
      list-style: none;
      display: flex;
      justify-content: space-around;
      align-items: center;
      position: absolute;
      width: auto;
      height: 20px;
      top: 90%;
      right: 4%;

      .color {
        border: 5px solid #ffffff;
        color: #fff;
        font-size: 20px;
        font-weight: 500;
        border-radius: 20px !important;
      }
      li {
        cursor: pointer;
        width: 101px;
        height: 1px;
        // border: 5px solid #ffffff;
        border: 3px solid #979797;
        // margin-left: 10px;
      }
      li:first-child {
        border-radius: 20px 0px 0px 20px;
      }
      li:last-child {
        border-radius: 0px 20px 20px 0px;
      }
    }
  }
}
</style>