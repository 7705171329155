<template>
  <div>
    <topHeadeg />

    <div class="banan">
      <!--      <img src="../assets/NewsCenter/banan.png" alt="" />imgbanner-->
      <img :src="imgbanner" alt="" />

      <h2>{{ mDescribe }}</h2>
    </div>
    <div class="intro">
      <div class="zedrt">
        <div class="tiA"></div>

        <div class="typeface">
          <p>新闻动态</p>
        </div>
        <div class="tiB"></div>
      </div>
    </div>

    <div
      class="center"
      v-for="item in News"
      :key="item.id"
      @click="Selected(item)"
    >
      <div class="center-img">
        <span
          v-if="
            item.coverImgList != null ||
            item.coverImgList != undefined ||
            (item.coverImgList != '' && item.coverImgList.length != 0)
          "
          ><img :src="boxwinde + item.coverImgList[0].url" alt=""
        /></span>
      </div>
      <div class="center-tit">
        <p class="tit-o">
          {{ item.nTitle }}
        </p>
        <p class="tit-p">
          {{ item.nAbstract }}
        </p>
      </div>
      <div class="titem">
        <div class="xip"></div>
        <div class="num" v-if="item.nTime != null">
          <h1>{{ item.nTime.substr(8, 2) }}</h1>
          <p>{{ item.nTime.substring(0, 7) }}</p>
        </div>
      </div>
    </div>
    <div class="tionan">
      <div class="bujkl">
        <div class="potty">
          <!--          <el-pagination-->
          <!--              @size-change="handleSizeChange"-->
          <!--              @current-change="handleCurrentChange"-->
          <!--              :current-page="currentPage"-->
          <!--              :page-sizes="[100, 200, 300, 400]"-->
          <!--              :page-size="pageSize"-->
          <!--              background-->
          <!--              layout="total, prev, pager, next, jumper"-->
          <!--              :total="total">-->
          <!--          </el-pagination>-->
          <p>{{ pageNum }}</p>
          <div class="opt">
            <div class="arrow" @click="prevPage(pageNum)">
              <i class="el-icon-arrow-left"></i>
            </div>
            <div class="arrow" @click="nextPage(pageNum)">
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <homeBottom />
  </div>
</template>

<script>
import { newslist } from "@/api";

import topHeadeg from "@/components/topHeadeg.vue";
import homeBottom from "@/components/homeBottom.vue";
export default {
  components: {
    topHeadeg,
    homeBottom,
  },
  data() {
    return {
      boxwinde: process.env.VUE_APP_SRC1,
      currentPage: 1,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      pageCount: "", //总页数
      News: [],
      imgbanner: "",
      mDescribe: "",
    };
  },
  mounted() {
    var bosdata = JSON.parse(localStorage.getItem("accessTop"));
    if (bosdata != null) {
      if (
        bosdata.mPictureList != null &&
        bosdata.mPictureList.length != "0" &&
        bosdata.mNo == "M20220507000001"
      ) {
        this.imgbanner = this.boxwinde + bosdata.mPictureList[0].url;
        this.mDescribe = bosdata.mDescribe;
      }
      // 本地存储里面的数据是字符串格式的，但我们需要的是对象格式
    } else {
      return [];
    }
    this.newslist();
  },
  methods: {
    newslist() {
      let lipme = { pageNum: this.pageNum, pageSize: this.pageSize };
      newslist(lipme).then((res) => {
        if (res.code == 200) {
          this.News = res.rows;
          this.total = res.total;
          this.pageCount = Math.ceil(res.total / this.pageSize); //获取总页数
        }
      });
    },

    //上一页
    prevPage(val) {
      if (this.pageNum == "1") {
        console.log("第一页!");
      } else {
        this.pageNum = this.pageNum - 1;
        this.newslist();
      }
    },

    //下一页
    nextPage(val) {
      if (this.pageNum == this.pageCount) {
        console.log("已经最后一页了!");
      } else {
        this.pageNum = this.pageNum + 1;
        this.newslist();
      }
    },

    Selected(num) {
      if (num.isFrame == "1") {
        let go = num.frameAddr;
        window.open(go, "_blank");
      } else {
        this.$router.push({ name: "Newsdetail", query: { nNo: num.nNo } });
      }
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      console.log(`当前页: ${val}`);
      this.newslist();
    },
  },
};
</script>

<style scoped lang="less">
.opt {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.arrow {
  width: 43px;
  height: 43px;
  text-align: center;
  line-height: 43px;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
}
.potty > p {
  width: 43px;
  height: 46px;
  text-align: center;
  line-height: 43px;
  background: #f44336;
  font-size: 18px;
  color: #fff;
}
.potty {
  width: 1150px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bujkl {
  width: 100%;
  height: 45px;
  overflow: hidden;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}
.tionan {
  margin-bottom: 50px;
  text-align: center;
}
.num {
  margin-left: 40px;
  h1 {
    color: #666666;
    font-size: 48px;
  }
  p {
    color: #a8a8a8;
    font-size: 14px;
    margin-top: 2px;
  }
}
.titem {
  display: flex;
  align-items: center;
}
.xip {
  width: 1px;
  height: 79px;
  border: 1px solid #dedede;
  background-color: #dedede;
}
.center-tit:hover > p {
  color: #f44336 !important;
}
.center-tit {
  width: 662px;
  // height: 58px;
}
.tit-p {
  color: #a8a8a8;
  font-size: 14px;
  margin-top: 23px;
  line-height: 20px;
}
.tit-o {
  font-size: 18px;
  color: #4f4d4a;
  font-weight: 600;
}
.center-tit:hover {
  color: red;
  cursor: pointer;
}

.center {
  width: 1150px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 72px;
}
.center-img {
  width: 166px;
  height: 126px;
  img {
    width: 100%;
    height: 100%;
  }
}
.inte {
  margin: 81px 0;
}

.banan {
  position: relative;
}
.banan h2 {
  display: inline-block;
  font-size: 30px;
  color: #fff;
  position: absolute;
  top: 25%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
  line-height: 369px;
}
.banan img {
  width: 100%;
  height: 100%;
}

//end
.typeface {
  color: #282828;
  font-size: 40px;
  font-weight: 600;
  margin: 0 29px;
}
.zedrt {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 75px 0;
}
.tiB {
  width: 120px;
  height: 9px;
  background: #0f4f7a;
  border-radius: 6px;
}
.tiA {
  width: 120px;
  height: 9px;
  background: #456e71;
  border-radius: 6px;
}
// 标题
</style>
