<template>
  <div class="header">
    <div class="card-carousel-wrapper">
      <div
        class="pize"
        style="background-color: #f44336"
        @click="Left()"
        :disabled="atHeadOfList"
      >
        <i class="el-icon-arrow-left" style="color: #fff"></i>
      </div>
      <div class="card-carousel">
        <div class="card-carousel--overflow-container">
          <div
            class="card-carousel-cards"
            :style="{
              transform: 'translateX' + '(' + currentOffset + 'px' + ')',
            }"
          >
            <div
              v-for="(item, index) in items"
              :key="index"
              :class="current == index ? 'test2' : 'card-carousel--card'"
              @mouseover="Select(index)"
            >
              <img
                :src="item.list[0].url"
                alt=""
                :class="current == index ? 'test1' : ''"
              />

              <div
                class="card-carousel--card--footer"
                v-show="current == index"
              >
                <div :class="current == index ? 'footer-tit2' : 'footer-tit'">
                  <div class="tipt-o">
                    <p>{{ item.chineseName }}</p>
                    <p>{{ item.englishName }}</p>
                    <div class="fullxia"></div>
                    <p class="dep">{{ item.businessDescription }}</p>
                    <div v-show="current === index" class="but" type="button">
                      <router-link :to="item.frameAddr">查看详情 </router-link>
                    </div>
                  </div>
                </div>
              </div>

              <div class="kuai" @mouseover="Select(index)">
                {{ item.chineseName }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="pize"
        style="background-color: #fff"
        @click="right()"
        :disabled="atEndOfList"
      >
        <i class="el-icon-arrow-right" style="color: #f44336"></i>
      </div>
    </div>
  </div>
</template>


<script>
import { Main } from "@/api";

export default {
  components: {},
  data() {
    return {
      boxwinde: window.location.origin, //获取当前地址路径
      listB: "",
      current: 1,
      currentOffset: 0,
      windowSize: 3,
      paginationFactor: 210,
      items: [],
    };
  },
  mounted() {
    this.Blist();
  },
  computed: {
    atEndOfList() {
      return (
        this.currentOffset <=
        this.paginationFactor * -1 * (this.items.length - this.windowSize)
      );
    },
    atHeadOfList() {
      return this.currentOffset === 0;
    },
  },
  methods: {
    Blist() {
      Main().then((res) => {
        this.items = res.data;
        this.items.forEach((res) => {
          res.list[0].url = process.env.VUE_APP_SRC1 + res.list[0].url;
        });
      });
    },
    Select(num) {
      console.log(num);
      this.current = num;
    },
    right() {
      if (!this.atEndOfList) {
        this.currentOffset -= this.paginationFactor;
      }
      this.current++;
      if (this.current >= 5) {
        this.current = 4;
      }
    },
    Left() {
      if (!this.atHeadOfList) {
        this.currentOffset += this.paginationFactor;
      }
      if (this.current > 0) {
        this.current--;
      }
    },
  },
};
</script>


<style scoped>
a {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
}

.fullxia {
  width: 30px;
  height: 1px;
  border: 1px solid #ffffff;
  background-color: #fff;
  margin-bottom: 20px;
}
.pize > i {
  font-size: 34px;
}
.pize {
  width: 51px;
  height: 51px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.kuai {
  width: 313px;
  height: 48px;
  background: #0f4f7a;
  border: 0px solid #0f4f7a;
  text-align: center;
  line-height: 48px;
  font-size: 14px;
  color: #fff;
}
.but {
  width: 156px;
  height: 43px;
  border: 1px solid #ffffff;
  color: #ffffff;
  background-color: #002b8e;
  margin-top: 130px;
  font-size: 18px;
  text-align: center;
  line-height: 43px;
}
.tipt-o p:nth-of-type(1) {
  margin-bottom: 10px;
}
.dep {
  width: 204px;
  height: 104px;
  font-size: 12px;
  line-height: 26px;
  margin-top: 20px;
}

.footer-tit {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-tit2 {
  width: 100%;
  height: 100%;
  line-height: normal;
  background-color: #002b8e;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-carousel-wrapper {
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666a73;
}

.card-carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1300px;
}
.card-carousel--overflow-container {
  height: 500px;
  line-height: 428px;
  overflow: hidden;
}
.card-carousel--nav__left,
.card-carousel--nav__right {
  display: inline-block;
  width: 15px;
  height: 15px;
  padding: 10px;
  box-sizing: border-box;
  border-top: 2px solid #42b883;
  border-right: 2px solid #42b883;
  cursor: pointer;
  margin: 0 10px;
  transition: transform 150ms linear;
}
.card-carousel--nav__left[disabled],
.card-carousel--nav__right[disabled] {
  opacity: 0.2;
  border-color: black;
}
.card-carousel--nav__left {
  transform: rotate(-135deg);
}
.card-carousel--nav__left:active {
  transform: rotate(-135deg) scale(0.9);
}
.card-carousel--nav__right {
  transform: rotate(45deg);
}
.card-carousel--nav__right:active {
  transform: rotate(45deg) scale(0.9);
}

.card-carousel-cards {
  display: flex;
  transition: transform 150ms ease-out;
  transform: translatex(0px);
  transition: all 0.4s;
  -moz-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  background-repeat: no-repeat; /* 不会重复 */
  background-position: center; /* 是为了让图片放大的时候从中部放大，不会偏移 */
  background-size: 100% 100%;
}
/* //放大后 */
.test2 {
  /* width: 361px; */
  height: 461px;
  background-size: 110% 110%;
  box-shadow: 0px 0px 8px 3px rgba(3, 42, 129, 0.39);
  font-size: 16px;
  background-color: #0f4f7a;
  z-index: 100;
  transform: scale(1);
}
.test1 {
  width: 361px;
  height: 461px;
  background-size: 110% 110%;
  box-shadow: 0px 0px 8px 3px rgba(3, 42, 129, 0.39);
  font-size: 16px;
  background-color: #0f4f7a;
  z-index: 100;
}
.card-carousel--card > img {
  width: 313px;
  height: 399px;
}
.card-carousel-cards .card-carousel--card {
  cursor: pointer;
  border-radius: 4px;
  z-index: 3;
}
.card-carousel-cards .card-carousel--card:first-child {
  width: 313px;
  height: 399px;
  margin-left: 0;
}
.card-carousel-cards .card-carousel--card:last-child {
  margin-right: 0;
}
.card-carousel-cards .card-carousel--card img {
  vertical-align: bottom;
  transition: opacity 150ms linear;
  user-select: none;
}
.card-carousel-cards .card-carousel--card img:hover {
  opacity: 0.5;
}
.card-carousel-cards .card-carousel--card--footer {
  width: 361px;
  height: 481px;
  transform: scale(1.1);
  border-top: 0;
  position: absolute;
  top: 0;
  z-index: 99;
  margin: 0 auto;
}
.card-carousel--card--footer p {
  color: #fff;
  font-size: 14px;
  width: 254px;
  margin-bottom: 10px;
}

h1 {
  font-size: 3.6em;
  font-weight: 100;
  text-align: center;
  margin-bottom: 0;
  color: #42b883;
}
</style>
