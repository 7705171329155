<template>
  <div>
    <div class="page">
      <div class="logo"><img src="../../assets/logez.png" alt="" /></div>

      <div class="pageui">
        <!-- <p>Home</p>
        <p>Product</p>
        <p>Programme</p>
        <p>Cooperation</p>
        <p>Journalism</p>
        <p>about</p> -->
      </div>

      <div class="engls">
        <span>EN</span>
        <span class="engspan">|</span>
        <span><router-link to="/">CN</router-link></span>
        <!-- <img src="../../assets/fad.png" alt="" /> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
a {
  text-decoration: none;
  color: #fff;
}

.engspan {
  display: block;
  width: auto !important;
  height: 34px;
  line-height: 34px;
  text-align: center;
  margin: 0 8px;
}
.engls > span {
  color: #fff;
}
.engls > img {
  width: 24px;
  height: 24px;
  margin: 0 8px;
  margin-top: 3px;
}
.engls {
  width: auto;
  margin-right: 57px;
  height: 93px;
  display: flex;
  align-items: center;
  background-color: #223752;
  font-size: 18px;

}
.pageui > p {
  width: 122px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  padding-top: 45px;
  border-right: 1px solid #223752;
}
.pageui {
    flex: 2;
  background-color: #223752;
  display: flex;
  color: #000;
}
.logo > img {
  width: 217px;
  height: 90px;
  margin-left: 44px;
  
}
.logo {
  width: 629px;
  height: 169px;
  text-align: left;
  background-color: #223752;
  line-height: 218px;
}
.page {
  width: 100%;
  height: 169px;
  display: flex;
  justify-content: space-around;
  background-color: #223752;
  clip-path: polygon(0 0, 100% 0, 100% 45%, 0 95%);
  position: fixed;
  top: 0;
  z-index: 999;
}
</style>