import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'

import 'animate.css'

import './utils/flexible' //一种适配方法没使用

import BaiduMap from 'vue-baidu-map'

Vue.use(BaiduMap, {
  ak: 'WAV0GCFPYLGzK9r6lQDwT7u3tkKHgUsL'
})


import VueLazyComponent from '@xunlei/vue-lazy-component'
Vue.use(VueLazyComponent)
Vue.prototype.$axios = axios;

import AOS from "aos";
import "../node_modules/aos/dist/aos.css";
Vue.use(AOS)


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')


