<template>
  <div>
    <topHeadeg />

    <div class="banan">
      <img src="../../assets/medicalcare/finance/banan.png" alt="" />
    </div>

    <div class="beij">
      <div class="zedrt">
        <div class="tiA"></div>

        <div class="typeface">
          <p>方案介绍</p>
        </div>
        <div class="tiB"></div>
      </div>
      <!-- 标题end -->

      <div
        class="tite"
        data-aos="fade-up"
        data-aos-once="true"
        data-aos-offset="0"
        data-aos-delay="500"
        data-aos-anchor-placement:="bottom-bottom"
      >
        致力于构建新一代信任机制，从业务、能力、技术三方面出发，为消费者提供最优服务，助力客户在信息时代的核心竞争力。
      </div>

      <div class="zedrt">
        <div class="tiA"></div>

        <div class="typeface">
          <p>服务特性</p>
        </div>
        <div class="tiB"></div>
      </div>
      <!-- 标题end -->
    </div>
    <div class="mead">
      <gradually :potty="medica" />
    </div>
    <div style="height: 200px"></div>

    <homeBottom />
  </div>
</template>

<script>
import topHeadeg from "@/components/topHeadeg.vue";
import homeBottom from "@/components/homeBottom.vue";
import gradually from "@/components/product/gradually.vue";
import AOS from "aos";

export default {
  components: {
    topHeadeg,
    homeBottom,
    gradually,
  },

  data() {
    return {
      Select: 1,
      medica: [
        {
          name: "全球性服务",
          img: require("../../assets/medicalcare/finance/duwtx1.png"),
        },
        {
          name: "智能化应用",
          img: require("../../assets/medicalcare/finance/duwtx2.png"),
        },
        {
          name: "企业专属定制",
          img: require("../../assets/medicalcare/finance/duwtx3.png"),
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
};
</script>

<style scoped lang="less">
.mead {
  margin-top: -150px;
}
.banan > img {
  width: 100%;
  height: 100%;
}
.banan {
  width: 100%;
  height: 100%;
}
.tite {
  width: 782px;
  height: 252px;
  margin: 0 auto;
  color: #212121;
  font-size: 20px;
  line-height: 43px;
  text-align: center;
  font-weight: 600;
}
//end
.typeface {
  color: #282828;
  font-size: 40px;
  margin: 0 29px;
  font-weight: bold;
}
.zedrt {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 81px;
}
.tiB {
  width: 120px;
  height: 9px;
  background: #0f4f7a;
  border-radius: 6px;
}
.tiA {
  width: 120px;
  height: 9px;
  background: #456e71;
  border-radius: 6px;
}
// 标题
.beij {
  padding-top: 81px;
  width: 100%;
  height: 700px;
  background: url("../../assets/medicalcare/finance/beijp.png") no-repeat center;
  background-size: 100%;
}
</style>