<template>
  <div>
    <topHeadeg />
    <div class="bana">
      <img src="../../assets/product/Corneal/banan.png" alt="" />
      <!-- <div class="titie">
        <p class="titie-s"><span>角膜</span>接触镜经营管理系统</p>
        <p class="totoe-p">基于医疗器械物流供应链和质量验查的综合信息化产品</p>
      </div> -->
    </div>
    <div class="introA">
      <div class="zedrt">
        <div class="tiA"></div>

        <div class="typeface">
          <p>产品特色</p>
        </div>
        <div class="tiB"></div>
      </div>
    </div>
    <gradually :potty="medica" />
    <div style="height: 100px"></div>

    <div
      class="ecog"
      data-aos="fade-up"
      data-aos-once="true"
      data-aos-offset="0"
      data-aos-delay="0"
      data-aos-anchor-placement:="bottom-bottom"
    >
      <p>
        大系统、大平台、大数据实现商品的生产、配送、防伪、追溯，避免公共医疗安全问题，且实现商品追踪与设备追踪，可从科研、生产、流动到使用过程进行全方位实时监控，有效提升医疗质量并降低管理成本。
      </p>
    </div>

    <div class="rnel">
      <img src="../../assets/product/Corneal/butt.png" alt="" />
      <div class="meng">
        <!-- <div class="neal">
          <div class="xiam"></div>
          <div class="menf-img">
            <img src="../../assets/product/Corneal/icon5.png" alt="" />
          </div>
          <p>产品优势</p>
          <p>Product advantages</p>
        </div> -->

        <div class="intro">
          <div class="zedrt">
            <div class="tiA"></div>

            <div class="typeface">
              <p style="color: #fff">产品优势</p>
            </div>
            <div class="tiB"></div>
          </div>
        </div>

        <div
          class="cornt"
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-offset="0"
          data-aos-delay="0"
          data-aos-anchor-placement:="bottom-bottom"
        >
          <div class="tages">
            <img src="../../assets/product/Corneal/tages1.png" alt="" />
            <span>系统符合国家医疗器械相关号令</span>
          </div>
          <div class="tages">
            <img src="../../assets/product/Corneal/tages2.png" alt="" />
            <span>系统符合企业使用要求</span>
          </div>
          <div class="tages">
            <img src="../../assets/product/Corneal/tages3.png" alt="" />
            <span>人工智能：扫码枪入库</span>
          </div>
        </div>
      </div>
    </div>

    <div class="introC">
      <div class="zedrt">
        <div class="tiA"></div>

        <div class="typeface">
          <p>成功案例</p>
        </div>
        <div class="tiB"></div>
      </div>
    </div>

    <div class="logo">
      <logo :itemimg="items" :distance="140"></logo>
    </div>
    <homeBottom />
  </div>
</template>

<script>
import topHeadeg from "@/components/topHeadeg.vue";
import homeBottom from "@/components/homeBottom.vue";
import logo from "@/components/logo.vue";
import gradually from "@/components/product/gradually.vue";
import AOS from "aos";

export default {
  components: {
    topHeadeg,
    homeBottom,
    logo,
    gradually,
  },
  data() {
    return {
      items: [
        { img: require("../../assets/logo/yanjialogo/yanja1.png") },
        { img: require("../../assets/logo/yanjialogo/yanja2.png") },
        { img: require("../../assets/logo/yanjialogo/yanja3.png") },
        { img: require("../../assets/logo/yanjialogo/yanja4.png") },
        { img: require("../../assets/logo/yanjialogo/yanja5.png") },
        { img: require("../../assets/logo/yanjialogo/yanja6.png") },
        { img: require("../../assets/logo/yanjialogo/yanja7.png") },
        { img: require("../../assets/logo/yanjialogo/yanja8.png") },
      ],
      medica: [
        {
          name: "物联网技术",
          img: require("../../assets/product/Corneal/duwtx1.png"),
        },
        {
          name: "区块链技术",
          img: require("../../assets/product/Corneal/duwtx2.png"),
        },
        {
          name: "人工智能",
          img: require("../../assets/product/Corneal/duwtx3.png"),
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
};
</script>

<style scoped lang="less">
.menf-img img {
  width: 60px !important;
  height: 60px;
  line-height: 60px;
}
.logo {
  margin-bottom: 133px;
}
.cornt {
  width: 1500px;
  margin: 0 auto;
  padding-top: 61px;
  display: flex;
  justify-content: space-between;
}
.tages {
  display: flex;
  align-items: center;
}
.tages span {
  color: #fff;
  font-size: 27px;
  margin-left: 50px;
}
.tages img {
  width: 80px !important;
  height: 80px;
}
.neal p {
  margin-bottom: 10px;
}
.neal {
  width: 210px;
  margin: 0 auto;
  text-align: center;
  color: #ffffff;
  font-size: 20px;
}
.menf-img {
  width: 70px;
  height: 70px;
  border: 1px solid #979797;
  margin: 0 auto;
  margin-bottom: 27px;
}
.xiam {
  width: 1px;
  height: 43px;
  border: 1px solid #979797;
  background-color: #979797;
  margin: 0 auto;
}
.meng {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  // background-color: rgba(0, 0, 0, 0.5);
}
.rnel > img {
  width: 100%;
  height: 533px;
}
.rnel {
  width: 100%;
  height: 533px;
  position: relative;
}
.ecog p {
  line-height: 30px;
  font-weight: 500;
}
.ecog {
  width: 1111px;
  height: 180px;
  margin: 0 auto;
  padding: 53px 0;
  color: #212121;
  font-size: 20px;
  text-align: center;
}
//car end
.ement-u {
  width: 1170px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 53px;
}
.ement p:hover {
  background-color: #f44336;
}
.ement p {
  height: 71px;
  background-color: #b6b6b6;
  color: #fff;
  line-height: 71px;
  text-align: center;
  font-size: 20px;
}
.ement img {
  width: 100%;
  height: 398px;
}
.ement {
  width: 371px;
  font-size: 0;
}
// car
.totoe-p {
  width: 720px;
  height: 75px;
  background-color: #6593df;
  color: #fff;
  padding: 0 44px;
  font-size: 30px;
  line-height: 75px;
  text-align: center;
  margin-top: 51px;
}
.titie-s span {
  font-size: 85px;
}
.titie-s {
  font-size: 55px;
  color: #ffffff;
}
.titie {
  position: absolute;
  bottom: 252px;
  right: 70px;
}
.bana img {
  width: 100%;
  height: 100%;
}
.bana {
  width: 100%;
  height: 808px;
}
.inter {
  margin: 91px 0;
}
//end
.typeface {
  color: #282828;
  font-size: 40px;
  font-weight: 600;
  margin: 0 29px;
}
.zedrt {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 75px 0;
}
.tiB {
  width: 128px;
  height: 11px;
  background: #0f4f7a;
  border-radius: 6px;
}
.tiA {
  width: 128px;
  height: 11px;
  background: #456e71;
  border-radius: 6px;
}
// 标题
</style>