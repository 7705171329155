<template>
  <div id="B-type">
    <div class="Group-2">
      <baidu-map class="map" :center="coordinate[0]" :zoom="coordinate[1].zomm">
        <bm-marker
          :position="coordinate[0]"
          :dragging="true"
          animation="BMAP_ANIMATION_BOUNCE"
        >
          <bm-label
            content=""
            :labelStyle="{ color: '#2B81FF', fontSize: '24px' }"
            :offset="{ width: -35, height: 30 }"
          />
        </bm-marker>
      </baidu-map>
    </div>
  </div>
</template>
<script>
export default {
  props: ["coordinate"],
  data() {
    return {};
  },
};
</script>
<style type="text/css">
.BMap_cpyCtrl {
  display: none;
}

.anchorBL {
  display: none;
}

.map {
  width: 100%;
  height: 122px;
}

.Group-2 {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

#B-type {
  width: 100%;
}
</style>
