<template>
  <div class="beei">
    <div class="intro">
      <div class="zedrt">
        <div class="tiA"></div>
        <div class="typeface">
          <p>{{ companyData.copyTitle }}</p>
        </div>
        <div class="tiB"></div>
      </div>
    </div>

    <div
      class="title"
      data-aos="fade-up"
      data-aos-once="true"
      data-aos-offset="0"
      data-aos-delay="0"
      data-aos-anchor-placement:="bottom-bottom"
    >
      {{ companyData.copywritingDescription }}
      <!--      祺机以信息化技术为核心，支持合作伙伴实现数字化转型。不断吸纳新技术，结合客户需求，持续完善服务体系。-->
    </div>

    <div
      data-aos="fade-up"
      data-aos-once="true"
      data-aos-offset="0"
      data-aos-delay="100"
      data-aos-anchor-placement:="bottom-bottom"
    >
      <div class="numio">
        <div class="kuai">
          <p class="uip">1000+</p>
          <p>截止2021年底，全球企业客户</p>
        </div>
        <div class="kuai">
          <p class="uip">130+</p>
          <p>标杆客户</p>
        </div>
      </div>

      <div class="numio">
        <div class="kuai">
          <p class="uip">11+</p>
          <p>行业经验</p>
        </div>
        <div class="kuai">
          <p class="uip">21</p>
          <p>专利及计算机软件著作权</p>
        </div>
      </div>
    </div>
    <div
      data-aos="fade-up"
      data-aos-once="true"
      data-aos-offset="0"
      data-aos-delay="0"
      data-aos-anchor-placement:="bottom-bottom"
    >
      <div class="graceful">
        <div class="honor">
          <img src="../../assets/Introduce/honor/zhen1.png" alt="" />
          <p>高新技术企业证书</p>
        </div>
        <div class="honor">
          <img src="../../assets/Introduce/honor/zhen2.png" alt="" />
          <p>软件企业证书</p>
        </div>
      </div>
      

      <div class="graceful">
        <div class="honor">
          <img src="../../assets/Introduce/honor/zhen3.png" alt="" />
          <p>信息系统安全等级保护备案证明</p>
        </div>
        <div class="honor">
          <img src="../../assets/Introduce/honor/zhen4.png" alt="" />
          <p>角膜零售软件产品证书</p>
        </div>
      </div>

      <div class="graceful">
        <div class="honor" >
        <img src="../../assets/Introduce/honor/zhen5.png" alt="" />
        <p>创新型中小企业证书</p>
      </div>
      <div class="honor">
          <img src="../../assets/Introduce/honor/zhen6.png" alt="" />
          <p>安防监控资质证书</p>
        </div>
      </div>
      <div class="dyuece">
        <img src="../../assets/Introduce/honor/zhonghj.png" alt="" />
        <p>IOS9001质量管理体系认证证书</p>
      </div>
     
    </div>
  </div>
</template>

<script>
import AOS from "aos";
import { company } from "@/api";
export default {
  data() {
    return {
      companyData: {},
      banner: "",
      queryForm: {
        currentPage: 1,
        pageSize: 10,
        isEnable: -1,
      },
    };
  },
  created() {
    company(this.queryForm).then((res) => {
      this.companyData = res.data[1];
      this.banner = process.env.VUE_APP_SRC1 + res.data[1].list[0].url;
    });
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
};
</script>

<style scoped lang="less">
.beei {
  width: 100%;
  background: url("../../assets/Introduce/honor/beij.png") no-repeat center
    center;
  background-size: cover;
  padding-top: 78px;
  padding-bottom: 110px;
}
.dyuece > p {
  color: #383838;
  font-size: 18px;
  margin-top: 19px;
}
.dyuece > img {
  width: 369px;
  height: 522px;
}
.dyuece {
  width: 369px;
  margin: 116px auto;
  text-align: center;
}
.honor > p {
  margin-top: 19px;
}
.graceful {
  width: 1100px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 116px;
}
.honor {
  width: 417px;
  text-align: center;
  color: #383838;
  font-size: 18px;
}
.honor > img {
  width: 417px;
  height: 288px;
}
.uip {
  font-size: 46px;
  color: #212121;
  margin-bottom: 18px;
  font-weight: 500;
}
.kuai {
  width: 250px;
  text-align: center;
}

.numio {
  width: 702px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  color: #9a9a9a;
  font-size: 18px;
  margin-top: 51px;
}
.title {
  font-size: 16px;
  color: #212121;
  width: 816px;
  height: 119px;
  text-align: center;
  line-height: 30px;
  margin: 0 auto;
  margin-top: 59px;
}

//end
.typeface {
  color: #282828;
  font-size: 40px;
  margin: 0 29px;
}
.zedrt {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 81px;
}
.tiB {
  width: 120px;
  height: 9px;
  background: #0f4f7a;
  border-radius: 6px;
}
.tiA {
  width: 120px;
  height: 9px;
  background: #456e71;
  border-radius: 6px;
}
// 标题
</style>
